import { Auth } from "aws-amplify";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../../../Api";
import { Doctor } from "../../../customTypes";
import { convertToJSON } from "../../../utils";
import { UserContext } from "../../context/UserContext";

export function useDoctorProfile() {
    const { setDoctorProfile, setAvatar, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const getDoctorProfile = async () => {
        return await sendRequest(`api/doctor`, "GET").then(async (result) => {
            if (!result || !setDoctorProfile) return;
            const d: Doctor = convertToJSON(result);
            try {
                if (d && d.avatar) {
                    const doctorAvatar = localStorage.getItem("doctorAvatar");
                    if (doctorAvatar) {
                        setAvatar(doctorAvatar);
                    } else {
                        await sendRequest(
                            `api/avatar?fileName=${d.avatar.split("/").at(-1)}`,
                            "GET"
                        ).then(async (response) => {
                            if (!response) return;
                            const url = response.replaceAll(`"`, ``);
                            getBase64FromUrl(url).then((result: any) => {
                                setAvatar(result);
                                localStorage.setItem("doctorAvatar", result);
                            });
                        });
                    }
                }
            } catch (error) {}

            setDoctorProfile(d);
            return d;
        });
    };

    const createDoctorProfile = async (
        username?: string,
        name?: string,
        medicalDomain?: string
    ) => {
        if (username && name && medicalDomain) {
            await sendRequest(
                "api/doctor",
                "POST",
                JSON.stringify({
                    email: username,
                    name: name,
                    medicalDomain: medicalDomain,
                    isDevUser: false,
                })
            ).then(async (result) => {
                if (!result) return;
                setUser(undefined);
                setDoctorProfile(undefined);
                await Auth.signOut();
                localStorage.removeItem("currentUserSignUp");
                navigate("/admin-confirmation");
            });
        }
    };

    const getBase64FromUrl = async (url: string) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    };

    return { getDoctorProfile, createDoctorProfile };
}
