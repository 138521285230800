import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { sendRequest } from "../../../Api";
import { Consultation, Preset } from "../../../customTypes";
import { BlueButton } from "../../../styles/Buttons.styles";
import { ANAMNESIS } from "../../../utils";
import Loading from "../../Loading";
import { ConsultationContext } from "../../context/ConsultationContext";
import { UserContext } from "../../context/UserContext";
import PrettyDropdown from "../../custom/PrettyDropdown";
import PresetSelects from "../../presets/PresetSelects";
import { Values } from "../../presets/PresetsForm";
import AdditionalPatientInformation from "../AdditionalPatientInformation";
import { usePresets } from "../custom/usePresets";
import UpdateableField from "../utils/UpdateableField";

export default function ConsultationRegenerateContent({
  setType,
}: {
  setType: Dispatch<SetStateAction<"SUMMARY" | "REGENERATE">>;
}) {
  const theme = useTheme();
  const { update, consultation, summarize } = useContext(ConsultationContext);
  const { doctorProfile } = useContext(UserContext);
  const [currentPresetType, setCurrentPresetType] = useState<
    Consultation["presetType"]
  >(consultation?.presetType || ANAMNESIS);
  const [presets, setPresets] = useState<Preset[]>([]);
  const [currentlySelectedPreset, setCurrentlySelectedPreset] = useState<
    Preset | undefined
  >();
  const { getPresets } = usePresets(
    setPresets,
    currentPresetType,
    setCurrentlySelectedPreset
  );

  const changeCurrentPreset = (type: Consultation["presetType"]) => {
    setCurrentPresetType(type);
    update({ presetType: type });
  };

  useEffect(() => {
    setCurrentlySelectedPreset(
      presets.find((p) => p.type === currentPresetType)
    );
  }, [currentPresetType]);

  useEffect(() => {
    getPresets();
    if (
      consultation &&
      (!consultation.transcribed || consultation.transcribed === "")
    ) {
    }
  }, []);

  const initialValues = {
    tone: currentlySelectedPreset?.tone || "Formalise",
    length: currentlySelectedPreset?.length || "Shorten",
    format: currentlySelectedPreset?.format || "Narrative",
    customPromptStyling: currentlySelectedPreset?.customPromptStyling || "",
  };

  const submit = async (values: Values) => {
    try {
      if (currentlySelectedPreset) {
        await sendRequest(
          "api/preset",
          "PUT",
          JSON.stringify({
            createdTimestamp: currentlySelectedPreset.createdTimestamp,
            ...values,
          })
        );
        summarize();
      }
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submit,
    enableReinitialize: true,
  });

  return !consultation ? (
    <Loading />
  ) : (
    <Box
      sx={{
        display: "flex",
        marginBottom: "3rem",
        width: "100%",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          height: "6rem",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            {t("letsTryAgain")}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {t("letsTryAgainDescription")}
          </Typography>
        </Box>
        <PrettyDropdown
          currentlySelected={currentPresetType}
          options={presets.map((p) => ({
            value: p.type,
          }))}
          colorful
          onChange={changeCurrentPreset}
          showToGoToPresetSettings
          append={
            <Typography
              sx={{
                color: theme.palette.grey[300],
                fontSize: "1rem",
              }}
            >
              {t("type")}
            </Typography>
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          marginTop: "2rem",
          gap: "2rem",
          justifyContent: "space-between",
          width: "100%",
          minHeight: "30rem",
          height: "100%",
        }}
      >
        <UpdateableField
          rows={20}
          name="summaries"
          update={update}
          defaultValue={consultation.summaries?.at(-1) || ""}
          currentSummaryIndex={0}
          consultation={consultation}
          sx={{
            width: "100%",
            "& .MuiInputBase-root": {
              borderRadius: "12px",
              height: "fit-content",
            },
          }}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          {consultation.presetType !== "Custom" && (
            <>
              <AdditionalPatientInformation showGenderDropdown />
              <Divider
                sx={{
                  margin: "1rem 0",
                  bgcolor: theme.palette.primary.dark,
                }}
              />
              {currentlySelectedPreset ? (
                <Box
                  sx={{
                    marginBottom: "2rem",
                  }}
                >
                  <PresetSelects
                    formik={formik}
                    inSettings={false}
                    currentPreset={currentlySelectedPreset}
                  />
                </Box>
              ) : (
                <Loading />
              )}
            </>
          )}

          <BlueButton
            onClick={async () => {
              await formik.submitForm();
              setType("SUMMARY");
            }}
            sx={{
              width: "fit-content",
              gap: "0.5rem",
            }}
            disabled={!consultation.transcribed}
          >
            <Box component="img" src="/svg/refresh.svg" />
            {t("regenerate")}
          </BlueButton>
          {consultation.presetType !== "Custom" && (
            <Divider
              sx={{
                margin: "1rem 0",
                bgcolor: theme.palette.primary.dark,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
