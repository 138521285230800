import { Box, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { sendRequest } from "../../Api";
import { BlueButton } from "../../styles/Buttons.styles";
import { UserContext } from "../context/UserContext";

type SupportValues = {
    supportText: string;
};

export default function ContactUs() {
    const location = useLocation();

    const [result, setResult] = useState<string>("");
    const { doctorProfile } = useContext(UserContext);

    const submitFeedback = async (values: SupportValues) => {
        try {
            if (values.supportText) {
                sendRequest(
                    "api/support",
                    "POST",
                    JSON.stringify({
                        userEmail: doctorProfile?.email,
                        supportText: values.supportText,
                        currentPath: location.pathname,
                    })
                ).then((result) => {
                    if (result) setResult(t("supportRequestSubmitted"));
                    formik.resetForm();
                });
            }
        } catch (error) {}
    };

    const initialValues = { supportText: "" };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: submitFeedback,
    });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            }}
            component="form"
            onSubmit={formik.handleSubmit}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <Box
                    component="img"
                    src="/svg/supportHelp.svg"
                    sx={{
                        width: "3rem",
                        height: "3rem",
                    }}
                />
                <Typography
                    sx={{
                        fontSize: "1.875rem",
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                    }}
                >
                    {t("howCanWeHelp")}
                </Typography>
            </Box>
            {result ? (
                <Typography>{result}</Typography>
            ) : (
                <>
                    <Typography>{t("letUsKnow")}</Typography>
                    <TextField
                        id="supportText"
                        name="supportText"
                        autoComplete="off"
                        variant="outlined"
                        InputProps={{
                            sx: {
                                backgroundColor: "white",
                                borderRadius: "12px",
                            },
                        }}
                        sx={{
                            width: "100%",
                            "& .MuiInputBase-root": {
                                borderRadius: "12px",
                                borderWidth: "0.5px",
                                height: "100%",
                            },
                        }}
                        value={formik.values.supportText}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.supportText &&
                            Boolean(formik.errors.supportText)
                        }
                        multiline
                        rows={4}
                        placeholder={t("howCanWeHelpYou")}
                    />
                    <Box>
                        <BlueButton type="submit">{t("send")}</BlueButton>
                    </Box>
                </>
            )}
        </Box>
    );
}
