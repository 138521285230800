import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { DEFAULT_BOX_SHADOW } from "../utils";
import SwitchableCardTopping from "./consultation/utils/SwitchableCardTopping";
import ContactUs from "./help/ContactUs";
import DownloadCenter from "./help/DownloadCenter";
import HowItWorks from "./help/HowItWorks";

export default function HelpPage() {
  const [type, setType] = useState<string>("HOW_IT_WORKS");
  const theme = useTheme();

  const renderType = () => {
    switch (type) {
      case "HOW_IT_WORKS":
        return <HowItWorks />;
      case "CONTACT_US":
        return <ContactUs />;
      case "DOWNLOAD_CENTER":
        return <DownloadCenter />;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        paddingBottom: "4rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "150%",
            fontWeight: "600",
          }}
        >
          {t("helpPage")}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "end", zIndex: "0" }}>
        <SwitchableCardTopping
          cardType="HOW_IT_WORKS"
          label={t("howAdiuWorks")}
          type={type}
          setType={setType}
        />
        <SwitchableCardTopping
          cardType="CONTACT_US"
          label={t("contactUs")}
          bothSlim
          type={type}
          setType={setType}
        />
        <SwitchableCardTopping
          cardType="DOWNLOAD_CENTER"
          label={t("downloadCenter")}
          bothSlim
          type={type}
          setType={setType}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          height: "100%",
          width: "100%",
          padding: "2rem",
          borderRadius: "40px",
          marginTop: "-2rem",
          ...DEFAULT_BOX_SHADOW,
          overflow: type === "PRESETS" ? "visible" : "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          position: "relative",
        }}
      >
        {renderType()}
      </Box>
    </Box>
  );
}
