import { Auth } from "aws-amplify";

const API_URL = process.env.REACT_APP_API_URL;

export async function sendRequest(
  url: string,
  method: string,
  body?: any,
  useAuth: boolean = true,
  contentType: string = "application/json"
) {
  if (API_URL) {
    let auth: any = {};
    if (useAuth) {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      auth["Authorization"] = token;
    }
    const requestOptions: any = {
      method: method,
      headers: {
        "Content-Type": contentType,
        ...auth,
      },
      redirect: "follow" as RequestRedirect,
      body: body,
    };
    const fetchUrl = url.includes("http") ? url : `${API_URL}${url}`;
    return fetch(fetchUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => result)
  }
}
