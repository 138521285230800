import { Box, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { object, string } from "yup";
import { sendRequest } from "../../Api";
import {
  SignInFormStyles,
  StyledSignInButton,
  StyledSignInInput,
} from "../../styles/SignIn.styles";

type WaitingListSignUpValues = {
  email: string;
};

export default function WaitingListSignUp() {
  const theme = useTheme();
  const [result, setResult] = useState<string>("");
  const waitingListSignUpValidationSchema = object().shape({
    email: string().email("Invalid email").required("Required"),
  });

  const waitingListSignUp = async (values: WaitingListSignUpValues) => {
    try {
      if (values.email) {
        const signUpUrl = process.env.REACT_APP_WAITING_LIST_API_URL;
        if (signUpUrl) {
          sendRequest(
            signUpUrl,
            "POST",
            JSON.stringify({
              email: values.email.toLowerCase(),
            }),
            false
          ).then((result) => {
            if (result) setResult(t("signUpSuccess"));
            formik.resetForm();
          });
        }
      }
    } catch (error) {}
  };

  const initialFormValues = { email: "" };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: waitingListSignUpValidationSchema,
    onSubmit: waitingListSignUp,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={SignInFormStyles}>
      {result ? (
        <Typography
          sx={{
            fontSize: "1.5rem",
          }}
        >
          {result}
        </Typography>
      ) : (
        <Box>
          <Typography
            sx={{
              color: theme.palette.grey[300],
              marginBottom: "0.25rem",
            }}
          >
            Email
          </Typography>
          <StyledSignInInput
            id="email"
            placeholder={t("enterYourEmail")}
            name="email"
            type="email"
            autoComplete="on"
            variant="outlined"
            value={formik.values.email.toLowerCase()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <StyledSignInButton
            type="submit"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            sx={{
              marginTop: "3.65rem",
            }}
          >
            {t("signUp")}
          </StyledSignInButton>
        </Box>
      )}
    </Box>
  );
}
