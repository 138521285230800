import getBlobDuration from "get-blob-duration";
import { useContext, useRef, useState } from "react";
import { sendRequest } from "../../../Api";
import { Consultation } from "../../../customTypes";
import { ConsultationContext } from "../../context/ConsultationContext";
import { UserContext } from "../../context/UserContext";
import useTimer from "./useTimer";

export default function useRecorder(
  update: (payload: any) => void,
  setIsLoading?: any,
  mobileConsultation?: Consultation | null,
  batchDurationInSeconds = "90"
) {
  const mediaRecorder = useRef<MediaRecorder>();
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [audioChunks, setAudioChunks] = useState<Blob[]>();
  const [isUploading, setIsUploading] = useState(false);
  const [isRecordingFinished, setIsRecordingFinished] = useState(false);
  const { handleStart, handlePauseResume, handleReset, time } = useTimer();
  const [error, setError] = useState("");
  const { consultation, getConsultation } = useContext(ConsultationContext);
  const keepRecordingRef = useRef(true);
  const clickedFinish = useRef(false);
  const clickedFinishTimestampRef = useRef(0);
  const timeRef = useRef(time);
  const { doctorProfile } = useContext(UserContext);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        return await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
      } catch (err: any) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const uploadBlob = async (blob: Blob) => {
    console.log("uploading...");
    sendRequest(
      `api/batchtranscribe?createdTimestamp=${
        consultation?.createdTimestamp || mobileConsultation?.createdTimestamp
      }`,
      "PUT",
      blob,
      true,
      "audio/mpeg"
    ).then(async (res) => {
      if (doctorProfile?.isDevUser) {
        console.log(res);
      }
      if (clickedFinish.current && timeRef.current >= 5000) {
        update({
          consultationStep: "SUMMARY",
          currentlyTranscribingAndSummarizing: true,
        });
        await sendRequest(
          `api/finishBatchTranscription?createdTimestamp=${
            consultation?.createdTimestamp ||
            mobileConsultation?.createdTimestamp
          }`,
          "POST",
          JSON.stringify({
            batchTranscriptionDuration: Math.round(
              (Date.now() - clickedFinishTimestampRef.current) / 1000
            ),
          })
        ).then(async (res) => {
          console.log("finished batch transcription and summarizing");
          setIsRecordingFinished(true);
        });
      }
    });
  };

  const startRecording = async () => {
    keepRecordingRef.current = true;
    const stream = await getMicrophonePermission();
    if (!stream) return;
    const media = new MediaRecorder(stream, {
      type: "audio/mpeg",
    } as MediaRecorderOptions);
    mediaRecorder.current = media;
    const localAudioChunks: Blob[] = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    mediaRecorder.current.onstop = async () => {
      const audioBlob = new Blob(localAudioChunks, {
        type: "audio/mpeg",
      });
      localAudioChunks.length = 0;
      uploadBlob(audioBlob);
    };

    const split = parseInt(batchDurationInSeconds);
    const splitMs = (isNaN(split) ? 10 : split) * 1000;

    setIsRecording(true);
    setIsRecordingPaused(false);
    handleStart();
    while (keepRecordingRef.current) {
      mediaRecorder.current.start();

      await new Promise((resolve) => setTimeout(resolve, splitMs));
      mediaRecorder.current.stop();
    }
  };

  const alwaysOnStop = () => {
    setIsRecording(false);
    keepRecordingRef.current = false;
    setIsRecordingPaused(false);
    handleReset();
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      clickedFinishTimestampRef.current = Date.now();
      alwaysOnStop();
      clickedFinish.current = true;
      timeRef.current = time;
      mediaRecorder.current.stop();
      if (time < 5000) {
        setError("Recording must be at least 5 seconds long.");
        return;
      }
      setIsUploading(true);

      update({
        recordingDuration: (time / 1000).toString(),
      });
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current) {
      keepRecordingRef.current = false;
      mediaRecorder.current.pause();
      setIsRecordingPaused(true);
      handlePauseResume();
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current) {
      keepRecordingRef.current = true;
      mediaRecorder.current.resume();
      setIsRecordingPaused(false);
      handlePauseResume();
    }
  };

  const upload = async (audio: Blob) => {
    setIsUploading(true);
    setIsLoading(true);
    const consultationToProcess = consultation || mobileConsultation;
    await sendRequest("api/getsignedurl", "PUT").then((response) => {
      if (!response) return;
      const url = response.replaceAll(`"`, ``);
      sendRequest(url, "PUT", audio, false, "audio/mpeg").then(async () => {
        await getBlobDuration(audio).then(async (blobDuration) => {
          update({
            audio: url.split("?")[0],
            recordingDuration: Math.round(blobDuration),
            consultationStep: "SUMMARY",
            currentlyTranscribingAndSummarizing: true,
          });
          if (consultationToProcess) {
            sendRequest(
              "api/transcribe",
              "POST",
              JSON.stringify({
                createdTimestamp: consultationToProcess?.createdTimestamp,
                audioPath: url.split("?")[0].split("/").at(-1),
              })
            );
          }
        });
      });
    });
  };

  const reset = () => {
    setIsRecording(false);
    setIsRecordingPaused(false);
    setIsUploading(false);
    setIsRecordingFinished(false);
    handleReset();
  };

  return {
    isRecording,
    startRecording,
    isRecordingPaused,
    resumeRecording,
    pauseRecording,
    stopRecording,
    isUploading,
    upload,
    time,
    error,
    setError,
    isRecordingFinished,
    reset,
  };
}
