import LogoDevIcon from "@mui/icons-material/LogoDev";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ConsultationContext } from "../context/ConsultationContext";
import Loading from "../Loading";
import { useConsultation } from "./custom/useConsultation";
import ConsultationRecording from "./recording/ConsultationRecording";
import ConsultationResults from "./results/ConsultationResults";

export default function ConsultationPage() {
    const { createdTimestamp } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {
        consultation,
        setConsultation,
        update,
        transcribeAndThenSummarize,
        summarize,
        createSummary,
        getConsultation,
        isCurrentlyPolling,
        setIsCurrentlyPolling,
        pollSummary,
    } = useConsultation(createdTimestamp, setIsLoading);

    return isLoading || !consultation ? (
        <Loading />
    ) : (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: "2rem",
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontSize: "150%",
                        fontWeight: "600",
                    }}
                >
                    {consultation.consultationStep === "SUMMARY"
                        ? consultation.consultationName
                        : t("newFile")}{" "}
                </Typography>
                {consultation.dev && (
                    <LogoDevIcon color="error" fontSize="large" />
                )}
            </Box>

            <ConsultationContext.Provider
                value={{
                    consultation: consultation,
                    setConsultation: setConsultation,
                    update: update,
                    transcribeAndThenSummarize: transcribeAndThenSummarize,
                    summarize: summarize,
                    createSummary: createSummary,
                    getConsultation: getConsultation,
                    isCurrentlyPolling: isCurrentlyPolling,
                    setIsCurrentlyPolling: setIsCurrentlyPolling,
                    pollSummary: pollSummary,
                    setIsLoading: setIsLoading,
                }}
            >
                {consultation.consultationStep === "SUMMARY" ? (
                    <ConsultationResults />
                ) : (
                    <ConsultationRecording />
                )}
            </ConsultationContext.Provider>
        </Box>
    );
}
