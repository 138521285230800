import { Box } from "@mui/material";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { sendRequest } from "../Api";
import { DEFAULT_TRANSITION } from "../utils";
import Loading from "./Loading";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

export default function PaymentEmbedding({
  setShowPaymentEmbedding,
  type,
}: {
  setShowPaymentEmbedding: (showPaymentEmbedding: boolean) => void;
  type: string;
}) {
  const [clientSecret, setClientSecret] = useState("");

  const initCheckoutSession = async () => {
    const stripeClientSecret = await sendRequest(
      "api/stripe",
      "POST",
      JSON.stringify({
        type: type,
      })
    );
    if (stripeClientSecret) {
      setClientSecret(stripeClientSecret.slice(1, -1));
    }
  };

  useEffect(() => {
    initCheckoutSession();
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
        left: "0",
        top: "0",
        zIndex: "999999999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...DEFAULT_TRANSITION,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }}
    >
      <Box
        sx={{
          width: "50%",
          padding: "1rem",
          backgroundColor: "white",
          borderRadius: "40px",
          position: "relative",
          maxHeight: "90%",
          overflowY: "scroll",
          scrollbarWidth: "none",
          ...DEFAULT_TRANSITION,
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          component="img"
          src="/svg/close.svg"
          onClick={() => setShowPaymentEmbedding(false)}
          sx={{
            position: "absolute",
            right: "2rem",
            top: "2rem",
            cursor: "pointer",
            ...DEFAULT_TRANSITION,
            "&:hover": {
              opacity: "0.5",
            },
          }}
        />
        {clientSecret ? (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        ) : (
          <Loading size="5rem" />
        )}
      </Box>
    </Box>
  );
}
