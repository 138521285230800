import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            backgroundColor: theme.palette.grey[200],
            height: "0.5rem",
            borderRadius: "40px",
            "& .MuiLinearProgress-bar": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

type LinearWithValueLabelProps = {
  size: number;
  isUploading: boolean;
};

export default function LinearWithValueLabel({
  size,
  isUploading,
}: LinearWithValueLabelProps) {
  const [progress, setProgress] = useState(10);
  const averageDEUploadSpeedInBytesPerSecond = 3593750;
  const expectedUploadTimeInSeconds =
    size / averageDEUploadSpeedInBytesPerSecond;
  const expectedPartUploadTime = expectedUploadTimeInSeconds / 10;

  useEffect(() => {
    if (!isUploading) {
      setProgress(100);
      return;
    }
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 90 ? prevProgress + 10 : 90
      );
    }, expectedPartUploadTime * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [isUploading]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
