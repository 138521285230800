import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Preset } from "../../customTypes";
import { ANAMNESIS, DEFAULT_BOX_SHADOW } from "../../utils";
import { usePresets } from "../consultation/custom/usePresets";
import SwitchableCardTopping from "../consultation/utils/SwitchableCardTopping";
import PresetsForm from "./PresetsForm";

export default function Presets() {
  const theme = useTheme();
  const [type, setType] = useState<string>(ANAMNESIS);
  const [presets, setPresets] = useState<Preset[]>([]);
  const [currentlySelectedPreset, setCurrentlySelectedPreset] = useState<
    Preset | undefined
  >();
  const { getPresets } = usePresets(
    setPresets,
    type,
    setCurrentlySelectedPreset
  );

  useEffect(() => {
    getPresets();
  }, []);

  useEffect(() => {
    setCurrentlySelectedPreset(presets.find((p) => p.type === type));
  }, [type]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        paddingBottom: "4rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "150%",
            fontWeight: "600",
          }}
        >
          {t("preSettingsForPrompts")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "end" }}>
        {presets &&
          presets.length > 0 &&
          presets.map((t, idx) => (
            <SwitchableCardTopping
              cardType={t.type}
              label={t.type}
              type={type}
              setType={setType}
              key={t.type}
              bothSlim={idx > 0}
            />
          ))}
      </Box>
      {presets && currentlySelectedPreset && (
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            height: "100%",
            width: "100%",
            padding: "2rem",
            borderRadius: "40px",
            marginTop: "-2rem",
            ...DEFAULT_BOX_SHADOW,
          }}
        >
          <PresetsForm
            currentlySelectedPreset={currentlySelectedPreset}
            getPresets={getPresets}
          />
        </Box>
      )}
    </Box>
  );
}
