import { Box, useTheme } from "@mui/material";
import { DEFAULT_BOX_SHADOW } from "../../utils";

export default function CardBottom({
  color,
  right,
}: {
  color?: string;
  right: string;
}) {
  const theme = useTheme();

  const triangleSize = "30px";

  const bg = color ? color : theme.palette.grey[100];

  const shadow = color ? {} : DEFAULT_BOX_SHADOW;

  return (
    <>
      <Box
        sx={{
          width: `calc(100% - ${right || "0"})`,
          height: "100%",
          backgroundColor: bg,
          ...shadow,
          borderRadius: "0 0 40px 40px",
        }}
      />
      <Box
        sx={(theme) => ({
          width: triangleSize,
          height: triangleSize,
          backgroundImage: `radial-gradient(circle at 100% 0, transparent 0%, transparent ${triangleSize}, ${bg} ${triangleSize});`,
          transform: "rotate(90deg)",
          marginRight: "-1rem",
          [theme.breakpoints.down("xl")]: {
            marginTop: "-3px",
          },
        })}
      />
    </>
  );
}
