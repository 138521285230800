import { AmplifyUser } from "@aws-amplify/ui";
import { t } from "i18next";
import { Consultation } from "./customTypes";

export const convertToJSON = (result: string) =>
  JSON.parse(result.replaceAll("True", "true").replaceAll("False", "false"));

export const convertToDate = (timestamp: string) =>
  new Date(parseInt(timestamp) * 1000).toLocaleString("de-DE");

export const getDaysLeft = (trialExpiryDate?: string) => {
  if (trialExpiryDate) {
    const today = new Date();
    const expiryDate = new Date(parseInt(trialExpiryDate) * 1000);
    if (today > expiryDate) return 0;
    const diffDays = Math.round(
      Math.abs((expiryDate as any) - (today as any)) / (1000 * 60 * 60 * 24)
    );
    return diffDays;
  }
  return 0;
};

export const convertToTableDate = (timestamp: string) => {
  const date = new Date(parseInt(timestamp) * 1000);
  return `${getWithLeadingZero(date.getHours())}:${getWithLeadingZero(
    date.getMinutes()
  )} - ${getWithLeadingZero(date.getDate())}.${getWithLeadingZero(
    date.getMonth() + 1
  )}.${date.getFullYear()}`;
};

export const convertToFullSearchDate = (timestamp: string) => {
  const date = new Date(parseInt(timestamp) * 1000);
  const month = date.toLocaleString("default", {
    month: "long",
  });
  return `${getWithLeadingZero(date.getHours())}:${getWithLeadingZero(
    date.getMinutes()
  )} - ${date.getDate()} ${month} ${date.getFullYear()}`;
};

const getWithLeadingZero = (number: number) =>
  number > 9 ? number : "0" + number;

export const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const convertToMMSS = (timeInSeconds: string) => {
  if (!timeInSeconds) {
    return "0:00";
  }
  const date = new Date(parseInt(timeInSeconds) * 1000);
  return `${date.getMinutes()}:${getWithLeadingZero(date.getSeconds())}`;
};

export const convertToHHMM = (timeInSeconds: string) => {
  return timeInSeconds
    ? new Date(parseInt(timeInSeconds) * 1000).toISOString().slice(11, 16)
    : "00:00";
};

export const convertToMinSec = (timeInSeconds: string) => {
  if (!timeInSeconds) return "0 min 0 sec";
  const date = new Date(parseInt(timeInSeconds) * 1000);
  return `${date.getMinutes()} min ${date.getSeconds()} sec`;
};

export function formatMinutesToHoursAndMinutes(minutes: number): string {
  if (minutes < 0) {
    throw new Error("Input must be a non-negative number of minutes");
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);

  const hoursString = hours > 0 ? `${hours}h ` : "";
  const minutesString = remainingMinutes > 0 ? `${remainingMinutes}min` : "";

  if (hours > 0 && remainingMinutes > 0) {
    return `${hoursString}${minutesString}`;
  } else if (hours > 0) {
    return hoursString;
  } else {
    return minutesString;
  }
}

export const checkUserProfileDev = (user?: AmplifyUser) =>
  Boolean(
    user &&
      user?.attributes &&
      user.attributes["profile"] &&
      user?.attributes["profile"] === "dev"
  );

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isTranscribeOrAudioPresent = (consultation?: Consultation) =>
  consultation &&
  ((consultation.transcribed && consultation.transcribed !== "") ||
    consultation.audio);

export const getTimeLeftInSeconds = (ttl?: string) => {
  if (ttl) {
    const now = new Date();
    const deleteDate = new Date(parseInt(ttl) * 1000);
    const difference = (deleteDate.getTime() - now.getTime()) / 1000;
    return difference;
  }
};

export const getTimeLeftHHMM = (difference?: number | string) => {
  if (difference) {
    if (typeof difference === "string") difference = parseInt(difference);
    return new Date(difference * 1000).toISOString().slice(11, 16);
  } else {
    return "00:00";
  }
};

export const DEFAULT_TRANSITION = {
  transition: "all .35s",
};

export const getConsultationStatusLabel = (
  consultationStatus: string,
  consultationType: string
) => {
  switch (consultationStatus) {
    case "DONE":
      return "Done";
    default:
      if (consultationType === ANAMNESIS) {
        return t("finishAnamnesis");
      } else {
        return t("finishSummary");
      }
  }
};

export const ANAMNESIS = "Anamnesis";

export const getValueOrEmpty = (value?: string) => {
  return value ? value : "";
};

export const DEFAULT_BOX_SHADOW = {
  boxShadow: "0 1rem 1rem rgba(0, 0, 0, 0.075)",
};

export const downloadConsentForm = () => {
  fetch(
    "https://adiu-consent.s3.eu-central-1.amazonaws.com/Adiu-Einwilligungserklaerung.pdf",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Adiu-Einwilligungserklaerung.pdf`);

      document.body.appendChild(link);

      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    });
};

export const downloadAdiuBestPractice = () => {
  fetch(
    "https://adiu-consent.s3.eu-central-1.amazonaws.com/Adiu_Best_Practice.pdf",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Adiu_Best_Practice.pdf`);

      document.body.appendChild(link);

      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    });
};
