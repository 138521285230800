import { AmplifyUser } from "@aws-amplify/ui";
import { Box, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { object, ref, string } from "yup";
import { sendRequest } from "../../Api";
import {
  SignInFormStyles,
  StyledSignInButton,
  StyledSignInInput,
} from "../../styles/SignIn.styles";
import { TemporaryUserProps } from "../Landing";
import { UserContext } from "../context/UserContext";

type NewPasswordValues = {
  password: string;
  confirmPassword: string;
};

type NewPasswordProps = {
  temporaryUser: TemporaryUserProps;
};

type NewPasswordLocation = {
  email: string;
  code: string;
};

export default function SetNewPassword() {
  const [data] = useState<NewPasswordLocation>(useLocation().state);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const newPasswordValidationSchema = object().shape({
    password: string()
      .required(t("newPassword"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        t("passwordRequirements")
      ),
    confirmPassword: string()
      .required(t("required"))
      .oneOf([ref("password")], t("passwordsMustMatch")),
  });

  const newPassword = async (values: NewPasswordValues) => {
    try {
      if (
        values.password &&
        values.confirmPassword &&
        values.password === values.confirmPassword
      ) {
        await Auth.forgotPasswordSubmit(
          data.email,
          data.code,
          values.password
        ).then(async () => {
          await Auth.signIn(data.email, values.password).then(
            async (user: AmplifyUser) => {
              await sendRequest(
                "api/doctor",
                "POST",
                JSON.stringify({
                  email: data.email,
                })
              );
              setUser(user);
              navigate("/");
            }
          );
        });
      }
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  const initialFormValues = { password: "", confirmPassword: "" };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: newPasswordValidationSchema,
    onSubmit: newPassword,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={SignInFormStyles}>
      <Typography
        sx={{
          fontSize: "125%",
          height: "1.8rem",
        }}
      >
        {t("newPasswordHeader")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          maxWidth: "32rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.grey[300],
              marginBottom: "0.25rem",
            }}
          >
            {t("password")}
          </Typography>
          <StyledSignInInput
            id="password"
            placeholder={t("enterNewPassword")}
            name="password"
            type="password"
            autoComplete="on"
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
          />
        </Box>
        <Typography
          color="error"
          sx={{
            fontSize: "70%",
            maxWidth: "24.5rem",
          }}
        >
          {formik.touched.password && formik.errors.password}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          maxWidth: "32rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.grey[300],
              marginBottom: "0.25rem",
            }}
          >
            {t("confirmPassword")}
          </Typography>
          <StyledSignInInput
            id="confirmPassword"
            placeholder={t("confirmYourPassword")}
            name="confirmPassword"
            type="password"
            autoComplete="on"
            variant="outlined"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
          />
        </Box>
        <Typography
          color="error"
          sx={{
            fontSize: "70%",
            maxWidth: "24.5rem",
          }}
        >
          {formik.touched.confirmPassword && formik.errors.confirmPassword}
        </Typography>
      </Box>

      <StyledSignInButton
        type="submit"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {t("signIn")}
      </StyledSignInButton>
    </Box>
  );
}
