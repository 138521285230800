import { Box, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useState } from "react";
import { object, ref, string } from "yup";
import { BlueButton } from "../styles/Buttons.styles";
import { StyledSignInInput } from "../styles/SignIn.styles";
import { UserContext } from "./context/UserContext";

type PopUpModalProps = {
  close: () => void;
};

type ChangePasswordValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

export default function PasswordChangeModal({ close }: PopUpModalProps) {
  const { user } = useContext(UserContext);
  const [success, setSuccess] = useState(false);
  const onSubmit = async (values: ChangePasswordValues) => {
    if (
      values.oldPassword &&
      values.newPassword &&
      values.newPassword === values.newPasswordConfirm
    ) {
      const response = await Auth.changePassword(
        user,
        values.oldPassword,
        values.newPassword
      );
      if (response === "SUCCESS") {
        setSuccess(true);
      }
    }
  };

  const validationSchema = object().shape({
    oldPassword: string().required(t("required")),
    newPassword: string()
      .required(t("required"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        t("passwordRequirements")
      ),
    newPasswordConfirm: string()
      .required(t("required"))
      .oneOf([ref("newPassword")], t("passwordsMustMatch")),
  });

  const initialSettingsValues = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  const formik = useFormik({
    initialValues: initialSettingsValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        left: "0",
        top: "0",
        zIndex: "9999999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "70%",
          backgroundColor: "white",
          borderRadius: "40px",
          padding: "2rem",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "2rem",
        }}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Box
          component="img"
          src="/svg/close.svg"
          sx={{
            position: "absolute",
            right: "2rem",
            top: "2rem",
            cursor: "pointer",
          }}
          onClick={() => {
            close();
            formik.resetForm();
          }}
        />
        {success ? (
          <Typography>{t("passwordChangeSuccess")}</Typography>
        ) : (
          <>
            <Typography>{t("changePassword")}</Typography>
            <Box>
              <Typography
                sx={{
                  color: theme.palette.grey[300],
                  marginBottom: "0.25rem",
                }}
              >
                {t("oldPassword")}
              </Typography>
              <StyledSignInInput
                id="oldPassword"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("oldPassword")}
                name="oldPassword"
                autoComplete="on"
                variant="outlined"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                error={
                  formik.touched.oldPassword &&
                  Boolean(formik.errors.oldPassword)
                }
                helperText={
                  formik.touched.oldPassword && formik.errors.oldPassword
                }
                InputProps={{
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "40px",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: theme.palette.grey[300],
                  marginBottom: "0.25rem",
                }}
              >
                {t("newPasswordLabel")}
              </Typography>
              <StyledSignInInput
                id="newPassword"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("newPasswordLabel")}
                name="newPassword"
                autoComplete="on"
                variant="outlined"
                type="password"
                isLongError={Boolean(
                  formik.touched.newPassword &&
                    formik.errors.newPassword &&
                    formik.errors.newPassword.length > 40
                )}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "40px",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: theme.palette.grey[300],
                  marginBottom: "0.25rem",
                }}
              >
                {t("confirmNewPassword")}
              </Typography>
              <StyledSignInInput
                id="newPasswordConfirm"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("confirmNewPassword")}
                name="newPasswordConfirm"
                autoComplete="on"
                variant="outlined"
                type="password"
                value={formik.values.newPasswordConfirm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.newPasswordConfirm &&
                  Boolean(formik.errors.newPasswordConfirm)
                }
                helperText={
                  formik.touched.newPasswordConfirm &&
                  formik.errors.newPasswordConfirm
                }
                InputProps={{
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "40px",
                  },
                }}
              />
            </Box>
            <BlueButton type="submit">{t("changePassword")}</BlueButton>
          </>
        )}
      </Box>
    </Box>
  );
}
