import {
  Box,
  Checkbox,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { boolean, object, ref, string } from "yup";

import { t } from "i18next";
import {
  SignInFormStyles,
  StyledSignInButton,
} from "../../styles/SignIn.styles";
import PrettyDropdown from "../custom/PrettyDropdown";
import AuthField from "./AuthField";

type SignUpValues = {
  name: string;
  email: string;
  medicalDomain: string;
  password: string;
  confirmPassword: string;
  termsAccept: boolean;
};

export default function SignUp() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [signingUp, setSigningUp] = useState<boolean>(false);
  const [amplifyError, setAmplifyError] = useState<string>();
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get("lang");

  const signUpValidationSchema = object().shape({
    email: string().email("Invalid email").required(t("required")),
    medicalDomain: string().required(t("required")),
    name: string().required(t("required")),
    password: string()
      .required(t("required"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        t("passwordRequirements")
      ),
    confirmPassword: string()
      .required(t("required"))
      .oneOf([ref("password")], t("passwordsMustMatch")),
    termsAccept: boolean().oneOf([true], t("privacyPolicyAccept")),
  });

  useEffect(() => {
    if (lang) {
      localStorage.setItem("language", lang || "en");
    }
  }, [lang]);

  const signUp = async (values: SignUpValues) => {
    try {
      const username = values.email.toLowerCase();
      const password = values.password;
      const medicalDomain = values.medicalDomain;
      const name = values.name;
      const confirmPassword = values.confirmPassword;
      if (
        username &&
        password &&
        medicalDomain &&
        name &&
        confirmPassword &&
        password === confirmPassword
      ) {
        await Auth.signUp({
          username,
          password,
          attributes: {
            "custom:fullname": name,
          },
          autoSignIn: {
            enabled: true,
          },
        });
        localStorage.setItem(
          "currentUserSignUp",
          JSON.stringify({
            username: username,
            medicalDomain: medicalDomain,
            name: name,
          })
        );
        navigate("/confirm-signup");
      }
    } catch (error) {
      setAmplifyError((error as any).message);
      console.log("error signing up", error);
    }
    setSigningUp(false);
  };

  const initialFormValues = {
    name: "",
    email: "",
    medicalDomain: t("generalMedicine"),
    password: "",
    confirmPassword: "",
    termsAccept: false,
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: signUpValidationSchema,
    onSubmit: signUp,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={SignInFormStyles}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <AuthField
          formik={formik}
          formikValue="name"
          placeholder="enterYourName"
        />
        <AuthField
          formik={formik}
          formikValue="email"
          placeholder="enterYourEmail"
        />

        <Box>
          <Typography
            sx={{
              color: theme.palette.grey[300],
              marginBottom: "0.25rem",
            }}
          >
            {t("medicalDomain")}
          </Typography>
          <PrettyDropdown
            width="100%"
            currentlySelected={
              formik.values.medicalDomain || t("generalMedicine")
            }
            onChange={(e, value) => {
              formik.setFieldValue("medicalDomain", value);
            }}
            options={[
              {
                value: t("generalMedicine"),
              },
              {
                value: t("internalMedicine"),
              },
              {
                value: t("cardiology"),
              },
              {
                value: t("pulmonology"),
              },
              {
                value: t("orthopaedics"),
              },
              {
                value: t("surgery"),
              },
              {
                value: t("dermatology"),
              },
              {
                value: t("psychologyPsychiatry"),
              },
            ]}
            formikLabel={"medicalDomain"}
          />
        </Box>
        <AuthField
          formik={formik}
          formikValue="password"
          placeholder="enterYourPassword"
          type="password"
          isLongError={Boolean(
            formik.touched.password &&
              formik.errors.password &&
              formik.errors.password.length > 40
          )}
        />
        <AuthField
          formik={formik}
          formikValue="confirmPassword"
          placeholder="enterYourConfirmPassword"
          type="password"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={formik.values.termsAccept}
            icon={<Box component="img" src="svg/checkboxUnchecked.svg" />}
            checkedIcon={<Box component="img" src="svg/checkboxChecked.svg" />}
            name="termsAccept"
            onChange={formik.handleChange}
          />
          <Typography
            sx={{
              color: theme.palette.grey[600],
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {t("agreeToPrivacyPolicy")}
            <Box
              component="span"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "https://www.adiu-health.de/datenschutz";
              }}
            >
              {t("privacy")}
            </Box>
            {t("privacyEnd")}
          </Typography>
        </Box>

        {formik.touched.termsAccept && formik.errors.termsAccept && (
          <Box>
            <Typography
              sx={{
                color: theme.palette.error.main,
              }}
            >
              {formik.errors.termsAccept}
            </Typography>
          </Box>
        )}
      </Box>
      {amplifyError && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.error.main,
            fontWeight: "600",
            fontSize: "1.25rem",
            position: "absolute",
            zIndex: "9",
            right: "30%",
            bottom: "0.7rem",
            [theme.breakpoints.down("md")]: {
              left: "2rem",
              fontSize: "1rem",
            },
            [theme.breakpoints.down("sm")]: {
              maxWidth: "7rem",
            },
          })}
        >
          {amplifyError}
        </Typography>
      )}

      <StyledSignInButton
        type="submit"
        onClick={() => {
          setAmplifyError("");
          if (
            Object.keys(formik.errors).length === 0 &&
            formik.values.email &&
            formik.values.password
          ) {
            setSigningUp(true);
          }
        }}
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {signingUp ? (
          <CircularProgress size={"1.25rem"} style={{ color: "white" }} />
        ) : (
          t("signUp")
        )}
      </StyledSignInButton>
    </Box>
  );
}
