import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Preset } from "../../customTypes";
import { ANAMNESIS } from "../../utils";
import Loading from "../Loading";
import { usePresets } from "../consultation/custom/usePresets";
import PresetsForm from "../presets/PresetsForm";

export default function PromptPresetsSettings() {
  const [type, setType] = useState<string>(ANAMNESIS);
  const [presets, setPresets] = useState<Preset[]>([]);
  const [currentlySelectedPreset, setCurrentlySelectedPreset] = useState<
    Preset | undefined
  >();
  const { getPresets } = usePresets(
    setPresets,
    type,
    setCurrentlySelectedPreset
  );

  useEffect(() => {
    getPresets();
  }, []);

  useEffect(() => {
    setCurrentlySelectedPreset(presets.find((p) => p.type === type));
  }, [type]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      {currentlySelectedPreset ? (
        <PresetsForm
          currentlySelectedPreset={currentlySelectedPreset}
          getPresets={getPresets}
          isSettings
          presets={presets}
          setType={setType}
        />
      ) : (
        <Loading />
      )}
    </Box>
  );
}
