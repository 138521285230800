import { Box, Typography } from "@mui/material";

type TimerProps = {
  time: number;
};

export default function Timer({ time }: TimerProps) {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "22px",
        }}
      >
        {`${("" + Math.floor((time / 60000) % 60)).slice(-2)} min ${(
          "" + Math.floor((time / 1000) % 60)
        ).slice(-2)} sec`}
      </Typography>
    </Box>
  );
}
