import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useContext } from "react";
import { ConsultationContext } from "../../context/ConsultationContext";
import useRecorder from "../custom/useRecorder";
import UploadBox from "./UploadBox";

export default function UploadAudioContent() {
  const theme = useTheme();
  const { update } = useContext(ConsultationContext);
  const { createSummary } = useContext(ConsultationContext);

  const { upload } = useRecorder(update, createSummary);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "2rem",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <UploadBox upload={upload} isAudio />
      <Box
        sx={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
          gap: "0.5rem",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "20px",
          }}
        >
          {t("uploadAudioFileDescription")}
        </Typography>
      </Box>
    </Box>
  );
}
