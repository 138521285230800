import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BlueButton, GreyButton } from "../../styles/Buttons.styles";
import { useConsultation } from "../consultation/custom/useConsultation";
import FullLengthDivider from "../custom/FullLengthDivider";

export default function DashboardTopButtons({ title }: { title: string }) {
  const { createConsultation } = useConsultation();
  const navigate = useNavigate();
  const [clickedNewRecording, setClickedNewRecording] = useState(false);
  const location = useLocation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: "150%",
          fontWeight: "600",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <BlueButton
          onClick={async () => {
            setClickedNewRecording(true);
            navigate(`/consultation`);
            const c = await createConsultation();
            navigate(`/consultation/${c.createdTimestamp}`);
            setClickedNewRecording(false);
          }}
          sx={{
            "&&": {
              height: "5rem",
              padding: "0 2rem",
            },
          }}
        >
          {clickedNewRecording ? (
            <CircularProgress
              size={"1.5rem"}
              style={{ color: theme.palette.text.primary }}
              sx={{
                marginRight: "1rem",
              }}
            />
          ) : (
            <Box
              component="img"
              src="/svg/new.svg"
              sx={{
                width: "1.5rem",
                marginRight: "1rem",
              }}
            />
          )}

          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "32px",
            }}
          >
            {t("newRecording")}
          </Typography>
        </BlueButton>
        <GreyButton
          isCurrentlySelected={location.pathname === "/open"}
          onClick={() => navigate("/open")}
          sx={{
            "&&": {
              height: "5rem",
              padding: "0 2rem",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "32px",
            }}
          >
            {t("openCases")}
          </Typography>
        </GreyButton>
        <GreyButton
          isCurrentlySelected={location.pathname === "/presets"}
          onClick={() => navigate("/presets")}
          sx={{
            "&&": {
              height: "5rem",
              padding: "0 2rem",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "32px",
            }}
          >
            {t("presets")}
          </Typography>
        </GreyButton>
      </Box>
      <FullLengthDivider />
    </Box>
  );
}
