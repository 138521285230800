import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const GradientDivider = styled(Box)(() => ({
  width: "100%",
  height: "1px",
  borderRadius: "40px",
  background:
    "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(178,221,255,1) 50%, rgba(0,0,0,0) 100%)",
  marginBottom: "1rem",
}));
