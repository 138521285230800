import { Box, Typography, useTheme } from "@mui/material";
import { BlueButton } from "../../styles/Buttons.styles";
import { downloadAdiuBestPractice } from "../../utils";
import FullLengthDivider from "../custom/FullLengthDivider";

export default function HowItWorks() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.125rem",
            fontWeight: "600",
          }}
        >
          Grundlagen
        </Typography>
        <Typography>
          Um Ihnen den einstig in Adiu einfacher zu gestalten haben wir im
          Folgenden die wichtigsten Tips für die optimale Nutzung unseres
          Programms zusammengefasst. Falls sie noch weitere informationen
          benötigen kontaktieren sie uns direkt.
        </Typography>
      </Box>

      <FullLengthDivider color={theme.palette.primary[600]} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.125rem",
            fontWeight: "600",
          }}
        >
          Rechtliche Grundlagen
        </Typography>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>1.</Typography>
          <Typography>
            Holen Sie immer das Einverständnis des Patienten ein, bevor Sie mit
            der Aufzeichnung beginnen. (Rechtlich ist es Ihnen nicht gestattet,
            eine Audiodatei von einem Gespräch mit einem Patienten OHNE seine
            Zustimmung zu erstellen. Überspringen Sie also niemals den Prozess,
            die rechtliche Zustimmung des Patienten einzuholen.)
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>2.</Typography>
          <Typography>
            Fügen Sie das Adiu-Einwilligungsformular einfach zu Ihren üblichen
            Praxis-Anmeldeunterlagen hinzu. (Um den Prozess zu vereinfachen,
            lassen Sie die Patienten die rechtliche Zustimmung mit Ihren
            regulären Unterlagen bei der Anmeldung in Ihrer Praxis
            unterzeichnen.)
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>3.</Typography>
          <Typography>
            Speichern Sie die Einwilligungsformulare sicher in Ihrem PVS-System,
            wie alle Ihre anderen Aufzeichnungen.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.125rem",
            fontWeight: "600",
          }}
        >
          Daten sichern
        </Typography>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>1.</Typography>
          <Typography>
            Alle Dateien werden zur Datensicherheit nur maximal 24 Stunden lang
            gespeichert.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>2.</Typography>
          <Typography>
            Vervollständigen Sie Ihre Bearbeitung oder laden Sie die Dateien
            herunter, bevor die Zeit abläuft.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.125rem",
            fontWeight: "600",
          }}
        >
          Audioqualität verbessern
        </Typography>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>1.</Typography>
          <Typography>
            Verwenden Sie einen PC mit eingebautem Mikrofon oder besorgen Sie
            sich ein Tischkonferenzmikrofon (z.B. www.tinyurl.com/5n7z84nz).
            Alternativ können Sie Ihr Mobiltelefon verwenden und den QR-Code auf
            der Aufnahmeseite scannen.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>2.</Typography>
          <Typography>
            Positionieren Sie das Mikrofon gut zwischen den Sprechenden.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>3.</Typography>
          <Typography>
            Wiederholen Sie besonders bei Patienten mit Dialekten oder Akzenten
            die wichtigsten Teile und sprechen Sie diese selbst laut und
            deutlich aus.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Typography>4.</Typography>
          <Typography>
            Überprüfen Sie immer die Zusammenfassungen auf medizinische
            Genauigkeit.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={downloadAdiuBestPractice}
      >
        <BlueButton>Download best practice guide</BlueButton>
      </Box>
      <FullLengthDivider color={theme.palette.primary[600]} />
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: "600",
        }}
      >
        Tutorials
      </Typography>
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: "600",
        }}
      >
        Erste Schritte
      </Typography>
      <iframe
        width="732"
        height="339"
        src="https://www.loom.com/embed/fd92d99b44f0426c866001819d905ef5"
        allowFullScreen
      />
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: "600",
        }}
      >
        Neue Aufnahme erstellen
      </Typography>
      <iframe
        width="732"
        height="339"
        src="https://www.loom.com/embed/5f532748b85249ac85f7622ce35cefc4"
        allowFullScreen
      />
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: "600",
        }}
      >
        Wie man Gespräche im Nachgang bearbeitet
      </Typography>
      <iframe
        width="732"
        height="339"
        src="https://www.loom.com/embed/96287ecd33f54a9c9729a023f963b96c"
        allowFullScreen
      />
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: "600",
        }}
      >
        Voreinstellungen ändern
      </Typography>
      <iframe
        width="732"
        height="339"
        src="https://www.loom.com/embed/3d0c186d1e094e3ca207316566b25235"
        allowFullScreen
      />
    </Box>
  );
}
