import { Box, Button, TextField, Typography } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { BlueButton } from "./Buttons.styles";

export const SignInFormStyles = (theme: Theme) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "1rem",
  zIndex: 9999999,
  maxWidth: "600px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0",
    gap: "2rem",
  },
});

const inputPadding = "0.75em";

export const StyledSignInHeader = styled(Typography)(({ theme }) => ({
  marginTop: "-1rem",
  fontSize: "2.25rem",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-0.75rem",
    marginLeft: "0.25rem",
  },
}));

export const StyledSignInInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isLongError",
})<{ isLongError?: boolean }>(({ theme, isLongError }) => ({
  width: "100%",
  padding: "0",
  "& label.Mui-focused": {
    borderWidth: "1px",
  },
  "& .MuiInput-underline:after": {
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderWidth: "1px",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
    },
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: isLongError ? "-2.75rem" : "-1.6rem",
    right: "0rem",
    maxWidth: "82%",
  },
  "& .MuiInputBase-input": {
    paddingTop: inputPadding,
    paddingBottom: inputPadding,
  },
  "input:-webkit-autofill::first-line, input:-webkit-autofill,  input:-webkit-autofill:hover,  input:-webkit-autofill:focus,  input:-webkit-autofill:active":
    {
      transition: "background-color 5000s ease-in-out 0s",
    },
}));

export const StyledErrorMessage = styled(Box)(({ theme }) => ({
  color: "#d32f2f",
  position: "absolute",
  marginTop: "-1rem",
  [theme.breakpoints.down("lg")]: {
    marginTop: "0",
  },
}));

export const CheckboxArea = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "auto",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-2rem",
    width: "105%",
  },
}));

export const StyledSignInButton = styled(BlueButton)(({ theme }) => ({
  position: "absolute",
  right: "0",
  bottom: "0",
  width: "22.5%",
  height: "2.25rem",
  [theme.breakpoints.down("lg")]: {
    width: "7rem",
    marginTop: "5.5rem",
    marginLeft: "0rem",
  },
}));

export const StyledChangeTemporaryPasswordButton = styled(Button)(
  ({ theme }) => ({
    marginTop: "-0.4rem",
    marginLeft: "26.2rem",
    width: "10rem",
    height: "3rem",
    [theme.breakpoints.down("lg")]: {
      width: "7rem",
      marginTop: "5rem",
      marginLeft: "-1rem",
    },
  })
);

export const CheckboxLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    maxWidth: "11rem",
    marginRight: "2rem",
  },
}));
