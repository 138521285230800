import { Box, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { sendRequest } from "../../Api";
import { Doctor } from "../../customTypes";
import {
    BlueButton,
    TransparentButton,
    TransparentButtonAsBox,
} from "../../styles/Buttons.styles";
import { convertToJSON } from "../../utils";
import PasswordChangeModal from "../PasswordChangeModal";
import { UserContextType } from "../context/UserContext";
import PrettyDropdown from "../custom/PrettyDropdown";
import InitialUserAvatar from "../doctor/InitialUserAvatar";
import { useDoctorProfile } from "../doctor/custom/useDoctorProfile";
import SettingsItem from "./SettingsItem";

type SettingsValues = {
    name: string;
    email: string;
    phone: string;
    language: "en" | "de";
};

export type DoctorProfileProps = {
    userContext: UserContextType;
};

export default function SettingsForm({ userContext }: DoctorProfileProps) {
    const { setDoctorProfile, doctorProfile, avatar } = userContext;

    const [showChangePasswordPopUp, setShowChangePasswordPopUp] =
        useState(false);
    const { getDoctorProfile } = useDoctorProfile();
    const [somethingChanged, setSomethingChanged] = useState(false);

    const theme = useTheme();

    const formikOnChange = (e: any) => {
        formik.handleChange(e);
        setSomethingChanged(true);
    };

    const upload = async (file: any) => {
        await sendRequest("api/avatar", "PUT").then((response) => {
            if (!response) return;
            const url = response.replaceAll(`"`, ``);
            sendRequest(url, "PUT", file, false, "image/png").then(async () => {
                sendRequest(
                    "api/doctor",
                    "PUT",
                    JSON.stringify({
                        avatar: url.split("?")[0],
                    })
                ).then((result) => {
                    if (!result) return;
                    localStorage.removeItem("doctorAvatar");
                    getDoctorProfile();
                });
            });
        });
    };

    const uploadAvatar = (event: any) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            upload(file);
        }
    };

    const navigate = useNavigate();
    const submitSettings = async (values: SettingsValues) => {
        try {
            localStorage.setItem("language", values.language);
            sendRequest(
                "api/doctor",
                "PUT",
                JSON.stringify({
                    ...values,
                    language: values.language,
                })
            ).then((result) => {
                if (!result) return;
                const d: Doctor = convertToJSON(result);
                setDoctorProfile(d);
            });
        } catch (error) {}
    };

    const validationSchema = object().shape({
        name: string().required(t("Required")),
    });

    const initialSettingsValues = {
        name: doctorProfile?.name || "",
        email: doctorProfile?.email || "",
        phone: doctorProfile?.phone || "",
        language: doctorProfile?.language || "de",
        medicalDomain: doctorProfile?.medicalDomain || "",
    };

    const formik = useFormik({
        initialValues: initialSettingsValues,
        validationSchema: validationSchema,
        onSubmit: submitSettings,
    });

    return (
        <>
            {showChangePasswordPopUp && (
                <PasswordChangeModal
                    close={() => setShowChangePasswordPopUp(false)}
                />
            )}
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Typography variant="h6">
                            {t("personalInfo")}
                        </Typography>
                        <Typography>{t("personalInfoDescription")}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1rem",
                        }}
                    >
                        <TransparentButton
                            onClick={(e) => {
                                navigate("/");
                                formik.handleReset(e);
                            }}
                            disabled={!somethingChanged}
                        >
                            {t("cancel")}
                        </TransparentButton>
                        <BlueButton
                            type="submit"
                            disabled={!somethingChanged}
                            id="saveChanges-btn"
                        >
                            {t("saveChanges")}
                        </BlueButton>
                    </Box>
                </Box>

                <SettingsItem label="language">
                    <PrettyDropdown
                        currentlySelected={formik.values.language}
                        onChange={(e, value) => {
                            formikOnChange(e);
                            formik.setFieldValue("language", value);
                        }}
                        options={[
                            {
                                value: "en",
                                label: t("english"),
                            },
                            {
                                value: "de",
                                label: t("german"),
                            },
                        ]}
                        formikLabel={"language"}
                    />
                </SettingsItem>
                <SettingsItem label="name">
                    <TextField
                        name="name"
                        placeholder={t("name")}
                        value={formik.values.name}
                        onChange={formikOnChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                        InputProps={{
                            sx: {
                                input: {
                                    "&::placeholder": {
                                        color: theme.palette.grey[300],
                                        opacity: "1",
                                    },
                                },
                            },
                        }}
                    />
                </SettingsItem>
                <SettingsItem label="emailAddress">
                    <TextField
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: theme.palette.grey[300],
                            },
                        }}
                        disabled
                        value={formik.values.email.toLowerCase()}
                    />
                </SettingsItem>
                <SettingsItem label="medicalDomain">
                    <PrettyDropdown
                        width="100%"
                        currentlySelected={
                            formik.values.medicalDomain || t("generalMedicine")
                        }
                        onChange={(e, value) => {
                            formikOnChange(e);
                            formik.setFieldValue("medicalDomain", value);
                        }}
                        options={[
                            {
                                value: t("generalMedicine"),
                            },
                            {
                                value: t("internalMedicine"),
                            },
                            {
                                value: t("cardiology"),
                            },
                            {
                                value: t("pulmonology"),
                            },
                            {
                                value: t("orthopaedics"),
                            },
                            {
                                value: t("surgery"),
                            },
                            {
                                value: t("dermatology"),
                            },
                            {
                                value: t("psychologyPsychiatry"),
                            },
                        ]}
                        formikLabel={"medicalDomain"}
                    />
                </SettingsItem>
                <SettingsItem
                    labelComponent={
                        <Box>
                            <Typography
                                sx={{
                                    width: "25rem",
                                    fontWeight: "500",
                                }}
                            >
                                {t("yourAvatar")}
                            </Typography>
                            <Typography
                                sx={{
                                    width: "25rem",
                                }}
                            >
                                {t("yourAvatarDescription")}
                            </Typography>
                        </Box>
                    }
                >
                    {avatar && avatar.length > 0 ? (
                        <Box
                            component="img"
                            src={avatar}
                            sx={{
                                height: "4rem",
                                borderRadius: "10px",
                            }}
                        />
                    ) : (
                        <InitialUserAvatar />
                    )}

                    <Box component="label">
                        <Box
                            hidden
                            component="input"
                            accept={".png, .jpg, .jpeg"}
                            type="file"
                            id="upload-avatar"
                            onChange={uploadAvatar}
                        />
                        <TransparentButtonAsBox>
                            {t("changeAvatar")}
                        </TransparentButtonAsBox>
                    </Box>
                </SettingsItem>
                <SettingsItem label="phoneNumber">
                    <TextField
                        placeholder={t("phoneNumber")}
                        name="phone"
                        value={formik.values.phone}
                        onChange={formikOnChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                        InputProps={{
                            sx: {
                                input: {
                                    "&::placeholder": {
                                        color: theme.palette.grey[300],
                                        opacity: "1",
                                    },
                                },
                            },
                        }}
                    />
                </SettingsItem>
                <SettingsItem label="password">
                    <BlueButton
                        onClick={() => {
                            setShowChangePasswordPopUp(true);
                        }}
                    >
                        {t("changePassword")}
                    </BlueButton>
                </SettingsItem>
            </Box>
        </>
    );
}
