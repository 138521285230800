import { AmplifyUser } from "@aws-amplify/ui";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { object, ref, string } from "yup";
import { sendRequest } from "../../Api";
import {
  SignInFormStyles,
  StyledSignInButton,
  StyledSignInInput,
} from "../../styles/SignIn.styles";
import { checkUserProfileDev } from "../../utils";
import { TemporaryUserProps } from "../Landing";
import { UserContext } from "../context/UserContext";

type ChangeTemporaryPasswordValues = {
  password: string;
  confirmPassword: string;
};

type ChangeTemporaryPasswordProps = {
  temporaryUser: TemporaryUserProps;
};

export default function ChangeTemporaryPassword() {
  const { setUser } = useContext(UserContext);
  const [temporaryUser] = useState<TemporaryUserProps>(useLocation().state);
  const theme = useTheme();
  const [signingIn, setSigningIn] = useState<boolean>(false);

  const changeTemporaryPasswordValidationSchema = object().shape({
    password: string()
      .required(t("createPermanentPassword"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        t("passwordRequirements")
      ),
    confirmPassword: string()
      .required(t("required"))
      .oneOf([ref("password")], t("passwordsMustMatch")),
  });

  const changeTemporaryPassword = async (
    values: ChangeTemporaryPasswordValues
  ) => {
    try {
      if (
        values.password &&
        values.confirmPassword &&
        values.password === values.confirmPassword
      ) {
        await Auth.signIn(
          temporaryUser.username,
          temporaryUser.temporaryPassword
        ).then((user: AmplifyUser) => {
          Auth.completeNewPassword(user, values.password).then(async (user) => {
            await sendRequest(
              "api/doctor",
              "POST",
              JSON.stringify({
                email: temporaryUser.username,
                isDevUser: checkUserProfileDev(user),
              })
            );
            setUser(user);
            window.location.href = "/";
          });
        });
      }
    } catch (error) {
      console.log("error signing in", error);
    }
    setSigningIn(false);
  };

  const initialFormValues = { password: "", confirmPassword: "" };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: changeTemporaryPasswordValidationSchema,
    onSubmit: changeTemporaryPassword,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={SignInFormStyles}>
      <Typography
        sx={{
          fontSize: "125%",
          height: "1.8rem",
          marginBottom: "1rem",
        }}
      >
        {t("createPermanentPassword")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.grey[300],
              marginBottom: "0.25rem",
            }}
          >
            Password
          </Typography>
          <StyledSignInInput
            id="password"
            placeholder={t("enterNewPassword")}
            name="password"
            type="password"
            autoComplete="on"
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            isLongError={Boolean(
              formik.touched.password &&
                formik.errors.password &&
                formik.errors.password.length > 40
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.grey[300],
              marginBottom: "0.25rem",
            }}
          >
            Confirm your password
          </Typography>
          <StyledSignInInput
            id="confirmPassword"
            placeholder={t("confirmYourPassword")}
            name="confirmPassword"
            type="password"
            autoComplete="on"
            variant="outlined"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
        </Box>
      </Box>

      <StyledSignInButton
        type="submit"
        onClick={() => {
          if (
            Object.keys(formik.errors).length === 0 &&
            formik.values.password &&
            formik.values.confirmPassword
          ) {
            setSigningIn(true);
          }
        }}
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {signingIn ? (
          <CircularProgress size={"1.25rem"} style={{ color: "white" }} />
        ) : (
          t("signIn")
        )}
      </StyledSignInButton>
    </Box>
  );
}
