import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";

export default function InitialUserAvatar(props: BoxProps) {
  const { doctorProfile, user } = useContext(UserContext);
  const theme = useTheme();
  const getDoctorInitials = () => {
    let l: any;
    if (doctorProfile && doctorProfile.name) {
      l = doctorProfile.name?.split(" ");
      if (l && l[0] && l[1]) {
        return l[0][0] + l[1][0];
      } else {
        return l[0][0];
      }
    } else {
      l = user?.attributes?.email.split("@")[0];
      if (l && l.includes(".")) {
        l = l.split(".");
      }
    }
    if (l && l[0] && l[1]) {
      return l[0][0] + l[1][0];
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: "200px",
        width: "2.5rem",
        height: "2.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>{getDoctorInitials()}</Typography>
    </Box>
  );
}
