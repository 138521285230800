import { Box, Rating, TextField, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../../../Api";
import {
  BlueButton,
  TransparentButton,
  WhiteButton,
} from "../../../styles/Buttons.styles";
import {
  DEFAULT_TRANSITION,
  getTimeLeftHHMM,
  getTimeLeftInSeconds,
} from "../../../utils";
import { ConsultationContext } from "../../context/ConsultationContext";
import { useConsultation } from "../custom/useConsultation";

type ModalContent = {
  header: string;
  oppositeActionButton?: JSX.Element;
  description?: string;
  warning?: boolean;
};

type Modals = {
  COPIED: ModalContent;
  DOWNLOADED: ModalContent;
  SAVED: ModalContent;
};

export default function PostActionPopUp({
  showActionDone,
  setShowActionDone,
  currentlySelectedSummaryIdx,
}: {
  showActionDone: "COPIED" | "DOWNLOADED" | "SAVED";
  setShowActionDone: Dispatch<
    SetStateAction<"COPIED" | "DOWNLOADED" | "SAVED" | undefined>
  >;
  currentlySelectedSummaryIdx: number;
}) {
  const { consultation } = useContext(ConsultationContext);
  const [ratingValue, setRatingValue] = useState<null | number>(null);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [result, setResult] = useState<string>();
  const { downloadConsultation } = useConsultation();
  const theme = useTheme();
  const navigate = useNavigate();

  const modals: Modals = {
    COPIED: {
      header: t("copiedHeader"),
      oppositeActionButton: (
        <TransparentButton
          onClick={() => {
            downloadConsultation(consultation);
          }}
          sx={{
            gap: "0.5rem",
            "&:hover": {
              "& .MuiBox-root": {
                filter:
                  "brightness(0) saturate(100%) invert(87%) sepia(19%) saturate(276%) hue-rotate(191deg) brightness(87%) contrast(89%)",
              },
            },
          }}
        >
          <Box
            component="img"
            src="/svg/download.svg"
            sx={{
              ...DEFAULT_TRANSITION,
            }}
          />
          {t("downloadText")}
        </TransparentButton>
      ),
    },
    DOWNLOADED: {
      header: t("downloadedHeader"),
      oppositeActionButton: (
        <TransparentButton
          onClick={() =>
            navigator.clipboard.writeText(
              consultation?.summaries?.at(currentlySelectedSummaryIdx) || ""
            )
          }
          sx={{
            gap: "0.5rem",
            "&:hover": {
              "& .MuiBox-root": {
                filter:
                  "brightness(0) saturate(100%) invert(87%) sepia(19%) saturate(276%) hue-rotate(191deg) brightness(87%) contrast(89%)",
              },
            },
          }}
        >
          <Box
            component="img"
            src="/svg/copy.svg"
            sx={{
              ...DEFAULT_TRANSITION,
            }}
          />
          {t("copyText")}
        </TransparentButton>
      ),
    },
    SAVED: {
      warning: true,
      header: t("savedHeader").replace(
        "{}",
        getTimeLeftHHMM(getTimeLeftInSeconds(consultation?.ttl))
      ),
      description: t("savedDescription"),
    },
  };

  const isCurrentModalWarning = Boolean(modals[showActionDone].warning);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(0,0,0,0.75)",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "999999",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          maxWidth: "41.5625rem",
          backgroundColor: isCurrentModalWarning
            ? theme.palette.grey[200]
            : theme.palette.primary.main,
          height: "23.75rem",
          borderRadius: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "2rem",
            right: "2rem",
            cursor: "pointer",
          }}
          component="img"
          src="/svg/close.svg"
          onClick={() => setShowActionDone(undefined)}
        />
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            padding: "2rem",
            borderBottom: `1px solid ${theme.palette.text.primary}`,
            alignItems: "center",
            height: "25%",
          }}
        >
          <Box
            component="img"
            src={`/svg/${
              isCurrentModalWarning ? "generalWarning" : "smileyFace"
            }.svg`}
          />
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: "600",
              }}
            >
              {modals[showActionDone].header}
            </Typography>
            {consultation && consultation.ttl && (
              <Typography
                sx={{
                  color: theme.palette.grey[600],
                  fontSize: isCurrentModalWarning ? "0.75rem" : "0.875rem",
                  maxWidth: "90%",
                }}
              >
                {Boolean(modals[showActionDone].description)
                  ? modals[showActionDone].description
                  : t("youCanAccess").replace(
                      "{}",
                      getTimeLeftHHMM(getTimeLeftInSeconds(consultation.ttl))
                    )}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            padding: "2rem 6.5rem",
            borderBottom: `1px solid ${theme.palette.text.primary}`,
            justifyContent: "center",
            height: "50%",
            flexDirection: "column",
          }}
        >
          {isCurrentModalWarning ? (
            <>
              <Typography
                sx={{
                  color: theme.palette.grey[600],
                }}
              >
                {t("pleaseDownloadDescription")}
              </Typography>
              <Box
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "12px",
                }}
                onClick={() => {
                  downloadConsultation(consultation);
                }}
              >
                <Box
                  component="img"
                  src="/svg/download.svg"
                  sx={{
                    filter:
                      "brightness(0) saturate(100%) invert(27%) sepia(55%) saturate(5323%) hue-rotate(350deg) brightness(88%) contrast(93%)",
                  }}
                />
                <Typography
                  sx={{
                    color: theme.palette.error[600],
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  {t("downloadNow")}
                </Typography>
              </Box>
            </>
          ) : result ? (
            <Typography
              sx={{
                fontSize: "1.125rem",
                alignSelf: "center",
                fontWeight: "600",
                color: theme.palette.grey[600],
              }}
            >
              {result}
            </Typography>
          ) : (
            <>
              <Typography
                sx={{
                  color: theme.palette.grey[600],
                }}
              >
                {t("haveFeedback")}
              </Typography>
              <Rating
                value={ratingValue}
                onChange={(event, newValue) => {
                  setRatingValue(newValue);
                }}
                icon={<Box component="img" src="/svg/starFilled.svg" />}
                emptyIcon={<Box component="img" src="/svg/starNotFilled.svg" />}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "end",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  placeholder={t("optionalFeedback")}
                  sx={{
                    width: "100%",
                  }}
                  value={feedbackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                />
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontWeight: "600",
                    fontSize: "0.875rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (feedbackText || ratingValue) {
                      sendRequest(
                        "api/feedback",
                        "POST",
                        JSON.stringify({
                          feedbackText: feedbackText,
                          rating: ratingValue,
                          consultationCreatedTimestamp:
                            consultation?.createdTimestamp,
                        })
                      ).then((result) => {
                        if (result) setResult("Feedback submitted");
                        setFeedbackText("");
                      });
                    }
                  }}
                >
                  {t("submit")}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            padding: "2rem",
            alignItems: "center",
            height: "25%",
            justifyContent: "end",
          }}
        >
          {modals[showActionDone].oppositeActionButton}
          <TransparentButton onClick={() => setShowActionDone(undefined)}>
            {t("close")}
          </TransparentButton>
          {isCurrentModalWarning ? (
            <BlueButton
              onClick={() => {
                setShowActionDone(undefined);
                navigate("/");
              }}
            >
              {t("acknowledged")}
            </BlueButton>
          ) : (
            <WhiteButton
              onClick={() => {
                setShowActionDone(undefined);
                navigate("/");
              }}
            >
              {t("goToDashboard")}
            </WhiteButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}
