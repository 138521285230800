import { Box, useTheme } from "@mui/material";

type CardToppingProps = {
  onClick?: any;
  selected?: boolean;
  bothSlim?: boolean;
  styles?: any;
  backgroundColor?: string;
  children: JSX.Element;
};

export default function CardTopping({
  onClick,
  selected,
  bothSlim,
  styles,
  backgroundColor,
  children,
}: CardToppingProps) {
  const theme = useTheme();
  const color = backgroundColor
    ? backgroundColor
    : selected || selected === undefined
    ? theme.palette.primary.light
    : (theme as any).palette.primary[300];
  const triangleSize = "30px";
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "end",
        marginLeft: bothSlim ? "-1.5em" : "0",
        zIndex: selected !== undefined && selected ? "999998" : "initial",
        ...styles,
      }}
      onClick={onClick}
    >
      {bothSlim && (
        <Box
          sx={{
            width: triangleSize,
            height: triangleSize,
            zIndex: selected ? "99999" : "999",
            backgroundImage: `radial-gradient(circle at 100% 0, transparent 0%, transparent ${triangleSize}, ${color} ${triangleSize});`,
            transform: "scale(-1, 1)",
            marginRight: "-2px",
          }}
        />
      )}
      <Box
        sx={{
          cursor: onClick && !selected ? "pointer" : "default",
          backgroundColor: color,
          borderRadius: "40px 40px 0 0",
          height: "5.5rem",
          marginBottom: "-2rem",
          width: "content",
          padding: backgroundColor ? "1rem 2rem 0 2rem" : "1rem 2rem 0 2rem",
          display: "flex",
          justifyContent: "center",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          width: triangleSize,
          height: triangleSize,
          zIndex: selected ? "99999" : "999",
          marginLeft: "-2px",
          backgroundImage: `radial-gradient(circle at 100% 0, transparent 0%, transparent ${triangleSize}, ${color} ${triangleSize});`,
        }}
      />
    </Box>
  );
}
