import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext, useState } from "react";
import { ConsultationInfo } from "../../../customTypes";
import { UserContext } from "../../context/UserContext";
import FullLengthDivider from "../../custom/FullLengthDivider";
import DropddownSortBy from "../DropdownSortBy";
import EnhancedTable from "../table/EnhancedTable";
import HoursSaved from "./HoursSaved";

export default function Overview() {
  const [selectedSortBy, setSelectedSortBy] =
    useState<keyof ConsultationInfo>("createdTimestamp");
  const { doctorProfile } = useContext(UserContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "150%",
            fontWeight: "600",
          }}
        >
          {t("transcripts")}
        </Typography>
        <DropddownSortBy
          selectedSortBy={selectedSortBy}
          setSelectedSortBy={setSelectedSortBy}
        />
      </Box>

      <EnhancedTable
        selectedSortBy={selectedSortBy}
        setSelectedSortBy={setSelectedSortBy}
      />
      {doctorProfile && <HoursSaved doctorProfile={doctorProfile} />}
      <FullLengthDivider />
    </Box>
  );
}
