import { Theme } from "@emotion/react";
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    grey: Palette["grey"];
  }

  interface PaletteOptions {
    grey?: PaletteOptions["grey"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
  interface TextFieldPropsColorOverrides {
    grey: true;
  }
}

export const theme: Theme = createTheme({
  palette: {
    text: {
      primary: "#293056",
      secondary: "rgba(41, 48, 86, 0.5)",
    },
    primary: {
      main: "#B2DDFF",
      300: "#F5FAFF",
      400: "#E8F1FA",
      500: "#D1E9FF",
      600: "#B2DDFF",
      700: "#84CAFF",
      800: "#1570EF",
      light: "#e7f2fa",
      dark: "#293056",
    },
    secondary: {
      main: "#E8F1FA",
      light: "#EAECF0",
      dark: "#B9C0D4",
    },
    error: {
      main: "#F97066",
      600: "#D92D20",
    },
    success: {
      main: "#32D583",
    },
    grey: {
      50: "#F6F6FB",
      100: "#EFF1F5",
      200: "#EAECF0",
      300: "#B9C0D4",
      400: "#7D89B0",
      500: "#5D6B98",
      600: "#4A5578",
    },
  },
  typography: {
    fontFamily: "Inter",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#293056",
          fontSize: "14px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#293056",
          borderRadius: "40px",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          height: "2.25rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          transition: "all 100ms linear",
          "& .MuiInputBase-root": {
            borderRadius: "40px",
            backgroundColor: "white",
            height: "2.25rem",
            transition: "all 100ms linear",
          },
          "& .MuiOutlinedInput-root": {
            transition: "all 100ms linear",
            "& fieldset": {
              border: `1px solid #B9C0D4`,
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              transition: "all 100ms linear",
            },
            "&:hover fieldset": {
              transition: "all 100ms linear",
              border: "1px solid rgba(0, 0, 0, 0)",
              boxShadow:
                "0px 0px 4px 2px #84CAFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            },
            "&.Mui-focused fieldset": {
              transition: "all 100ms linear",
              border: "1px solid rgba(0, 0, 0, 0)",
              boxShadow:
                "0px 0px 4px 2px #84CAFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "2.25rem",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: "40px",
        },
      },
    },
  },
});
