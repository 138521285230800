import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { sendRequest } from "../../Api";
import Loading from "../Loading";
import { UserContext } from "../context/UserContext";
import SubscriptionCard from "./SubscriptionCard";

export default function MySubscription({ title }: { title: string }) {
  const { doctorProfile, setDoctorProfile } = useContext(UserContext);

  const urlParams = new URLSearchParams(window.location.search);
  const [sessionId, setSessionId] = useState<string | null>(
    urlParams.get("session_id")
  );

  const getSessionStatus = async () => {
    await sendRequest(`api/stripe?session_id=${sessionId}`, "GET").then(
      (response) => {
        if (response) {
          const d = JSON.parse(response);
          setDoctorProfile(d);
        }
      }
    );
    resetQueryString();
    setSessionId(null);
  };

  const resetQueryString = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("session_id");
    window.history.replaceState(null, "null", url);
  };

  useEffect(() => {
    if (sessionId) {
      getSessionStatus();
    }
  }, [sessionId]);

  const cards = [
    {
      type: "trial",
      title: "Free",
      body: ["14 Tage kostenlos", "Ein Behandler", "Unbegrenzte Nutzung"],
    },
    {
      type: "monthly",
      title: "150€/m",
      body: [
        "Monatliche Bezahlung",
        "Ein Behandler",
        "Monatlich Kündbar",
        "Unbegrenzte Nutzung",
        "Kostenloser Support",
      ],
    },
    {
      type: "yearly",
      title: "100€/m",
      body: [
        "Jährliche Bezahlung",
        "Ein Behandler",
        "Jährlich Kündbar",
        "Unbegrenzte Nutzung",
        "Kostenloser Support",
      ],
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.25rem",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "1.125rem",
          }}
        >
          {t(title)}
        </Typography>
        {sessionId && (
          <Box>
            <Loading size="3rem" />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          marginTop: "2rem",
          paddingBottom: "4rem",
          gap: "2rem",
        }}
      >
        {doctorProfile ? (
          <>
            {cards.map((card, idx) => (
              <SubscriptionCard
                body={card.body}
                title={card.title}
                type={card.type}
                key={idx}
                currentlySelected={doctorProfile.license.type === card.type}
              />
            ))}
          </>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
}
