import { Box, Divider, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { Preset } from "../../customTypes";
import { DEFAULT_BOX_SHADOW, DEFAULT_TRANSITION } from "../../utils";
import PrettyDropdown from "../custom/PrettyDropdown";

export default function PresetSelects({
  formik,
  setSomethingChanged,
  inSettings = true,
  currentPreset,
}: {
  formik: any;
  setSomethingChanged?: Dispatch<SetStateAction<boolean>>;
  inSettings?: boolean;
  currentPreset: Preset;
}) {
  const [modalOpen, setModalOpen] = useState<string | null>(null);
  const theme = useTheme();

  const fields = [
    {
      name: "tone",
      helperText0: t("formaliseHelper"),
      helperText1: t("simplifyHelper"),
      values: [
        {
          label: t("formalise"),
          value: "Formalise",
        },
        {
          label: t("simplify"),
          value: "Simplify",
        },
      ],
    },
    {
      name: "length",
      helperText0: t("shortenHelper"),
      helperText1: t("extendHelper"),
      values: [
        {
          label: t("shorten"),
          value: "Shorten",
        },
        {
          label: t("extend"),
          value: "Extend",
        },
      ],
    },
    {
      name: "format",
      helperText0: t("narrativeHelper"),
      helperText1: t("bulletPointsHelper"),
      values: [
        {
          label: t("narrative"),
          value: "Narrative",
        },
        {
          label: t("bulletPoints"),
          value: "Bullet points",
        },
      ],
    },
    {
      name: "focus",
      helperText0: t("speedHelper"),
      helperText1: t("qualityHelper"),
      values: [
        {
          label: t("speed"),
          value: "Speed",
        },
        {
          label: t("quality"),
          value: "Quality",
        },
      ],
      default: "Quality",
    },
  ];

  const formikOnChange = (e: any) => {
    formik.handleChange(e);
    if (setSomethingChanged) {
      setSomethingChanged(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "1rem",
      }}
    >
      {fields.map((f) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
          key={f.name}
        >
          {inSettings && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Divider
                sx={{
                  width: "calc(100% + 4rem)",
                }}
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: inSettings ? "10rem" : "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "5rem",
                gap: "0.5rem",
              }}
            >
              <Typography>{t(f.name)}</Typography>
              <Box
                onMouseEnter={() => {
                  setModalOpen(f.name);
                }}
                onMouseLeave={() => {
                  setModalOpen(null);
                }}
                sx={{
                  color: theme.palette.text.primary,
                  border: `1px solid ${theme.palette.text.primary}`,
                  height: "1rem",
                  width: "1rem",
                  fontSize: "0.55rem",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "40px",
                  cursor: "pointer",
                }}
              >
                ?
              </Box>

              <Box
                sx={{
                  padding: "2rem 1.5rem",
                  position: "absolute",

                  maxWidth: "60rem",
                  left: inSettings ? "8rem" : "unset",
                  right: inSettings ? "unset" : "1rem",
                  bottom: inSettings ? "unset" : "4rem",
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: "40px",
                  ...DEFAULT_BOX_SHADOW,
                  ...DEFAULT_TRANSITION,
                  opacity: modalOpen === f.name ? 1 : 0,
                  zIndex: modalOpen === f.name ? 999999 : -1,
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <Box
                  component="img"
                  src="/svg/supportHelp.svg"
                  sx={{
                    width: "3rem",
                    height: "3rem",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.125rem",
                    }}
                  >
                    {t(f.name)}
                  </Typography>
                  {f.values.map((v, idx) => (
                    <Typography
                      key={idx}
                      sx={{
                        fontSize: "0.875rem",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          fontWeight: "600",
                        }}
                      >
                        {v.label}
                      </Box>
                      {": "}
                      {f[`helperText${idx}`]}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
            <PrettyDropdown
              key={currentPreset.type}
              currentlySelected={currentPreset[f.name] || f.default}
              options={f.values.map((v) => ({
                label: v.label,
                value: v.value,
              }))}
              onChange={(e, value) => {
                formikOnChange(e);
                formik.setFieldValue(f.name, value);
              }}
              formikLabel={f.name}
              showToGoToPresetSettings={!inSettings}
              width={inSettings ? "30rem" : undefined}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
