import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext } from "react";
import { Consultation } from "../../customTypes";
import { ConsultationContext } from "../context/ConsultationContext";
import PrettyDropdown from "../custom/PrettyDropdown";
import UpdateableField from "./utils/UpdateableField";

export default function AdditionalPatientInformation({
  showGenderDropdown = false,
  updateMobile,
  consultationMobile,
}: {
  showGenderDropdown?: boolean;
  updateMobile?: (payload: any) => void;
  consultationMobile?: Consultation;
}) {
  const { consultation, update } = useContext(ConsultationContext);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.125rem",
            fontWeight: "600",
          }}
        >
          {t("additionalPatientInformation")}
        </Typography>
        {showGenderDropdown && (
          <Box
            sx={{
              gap: "0.75rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>{t("selectGender")}</Typography>
            <PrettyDropdown
              currentlySelected={consultation?.patientGender}
              onChange={(value) => {
                update({
                  patientGender: value,
                });
              }}
              width="7.775rem"
              options={[
                {
                  value: "FEMALE",
                  label: t("female"),
                },
                {
                  value: "MALE",
                  label: t("male"),
                },
              ]}
            />
          </Box>
        )}
      </Box>

      <UpdateableField
        name="additionalPatientInformation"
        placeholder={t("additionalPatientInformationPlaceholder")}
        update={updateMobile ? updateMobile : update}
        defaultValue={
          consultationMobile
            ? consultationMobile.additionalPatientInformation
            : consultation?.additionalPatientInformation || ""
        }
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "12px",
            height: "100%",
          },
        }}
        rows={2}
      />
    </Box>
  );
}
