import { Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import CardTopping from "./CardTopping";

type SwitchableCardTopppingProps = {
  cardType: string;
  label: string;
  type: string;
  setType: Dispatch<SetStateAction<any>>;
  bothSlim?: boolean;
};

export default function SwitchableCardTopping({
  cardType,
  label,
  type,
  setType,
  bothSlim,
}: SwitchableCardTopppingProps) {
  const theme = useTheme();
  return (
    <CardTopping
      onClick={() => setType(cardType)}
      selected={type === cardType}
      bothSlim={bothSlim}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "16px",
          color:
            type === cardType
              ? theme.palette.text.primary
              : theme.palette.grey[300],
        }}
      >
        {t(label)}
      </Typography>
    </CardTopping>
  );
}
