import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { t } from "i18next";
import { useEffect } from "react";
import { ConsultationInfo } from "../../../customTypes";
import { Order } from "./EnhancedTable";

type HeadCell = {
  disablePadding: boolean;
  id: keyof ConsultationInfo;
  label: string;
};

type EnhancedTableHeadProps = {
  numSelected: number;
  onRequestSort: (property: keyof ConsultationInfo, direction?: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  selectedSortBy: keyof ConsultationInfo;
};

export default function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  selectedSortBy,
}: EnhancedTableHeadProps) {
  const theme = useTheme();
  const headCells: readonly HeadCell[] = [
    {
      id: "consultationName",
      disablePadding: false,
      label: t("fileName"),
    },
    {
      id: "createdTimestamp",
      disablePadding: false,
      label: t("created"),
    },
    {
      id: "recordingDuration",
      disablePadding: false,
      label: t("duration"),
    },
    {
      id: "consultationStatus",
      disablePadding: true,
      label: t("status"),
    },
    {
      id: "ttl",
      disablePadding: false,
      label: t("timeLeft"),
    },
    {
      id: "delete" as any,
      disablePadding: true,
      label: "",
    },
  ];
  const createSortHandler = (property: keyof ConsultationInfo) => {
    onRequestSort(property);
  };

  useEffect(() => {
    onRequestSort(selectedSortBy, "desc");
  }, [selectedSortBy]);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            paddingLeft: "2rem",
            borderBottomColor: theme.palette.text.secondary,
          }}
        >
          <Checkbox
            icon={<Box component="img" src="/svg/checkboxUnchecked.svg" />}
            checkedIcon={<Box component="img" src="/svg/checkboxChecked.svg" />}
            indeterminateIcon={
              <Box component="img" src="/svg/checkboxIndeterminate.svg" />
            }
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "selectAllConsultations",
            }}
            style={{
              marginRight: "1rem",
            }}
            sx={{
              zIndex: "9999",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.id === "recordingDuration" ? "10%" : "18%",
              borderBottomColor: theme.palette.text.secondary,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => createSortHandler(headCell.id)}
              sx={{
                fontWeight: "400",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
