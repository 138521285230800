import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { ConsultationStatus } from "../../../../customTypes";
import {
  DEFAULT_TRANSITION,
  getConsultationStatusLabel,
} from "../../../../utils";

type StatusProps = {
  consultationStatus: ConsultationStatus;
  consultationType: string;
};

const commonBoxStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "40px",
  padding: "0.5rem 1rem",
};

export default function Status({
  consultationStatus,
  consultationType,
}: StatusProps) {
  const theme = useTheme();
  const done = consultationStatus === "DONE";

  return done ? (
    <Box
      sx={{
        backgroundColor: "white",
        width: "7.5rem",
        height: "2.25rem",
        border: `0.5px solid ${theme.palette.success.main}`,
        gap: "0.5rem",
        ...commonBoxStyles,
      }}
    >
      <Box component="img" src="/svg/checkGreen.svg" />
      <Typography
        sx={{
          fontSize: "12px",
          color: theme.palette.success.main,
          fontWeight: "500",
        }}
      >
        {t("done")}
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        backgroundColor: theme.palette.text.primary,
        width: "11rem",
        height: "2.25rem",
        fontSize: "12px",
        color: "white",
        fontWeight: "500",
        ...commonBoxStyles,
        ...DEFAULT_TRANSITION,
        "&:hover": {
          backgroundColor: theme.palette.primary[700],
          "&&": {
            color: "#293056",
          },
        },
      }}
    >
      {getConsultationStatusLabel(consultationStatus, consultationType)}
    </Box>
  );
}
