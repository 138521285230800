import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const backgroundProperties = {
  backgroundImage: `url(/landingBackground.png)`,
  backgroundSize: "cover",
  backgroundPosition: "70% 100%",
  backgroundRepeat: "no-repeat",
};

export const StyledLandingComponent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  height: "100%",
  ...backgroundProperties,
}));

export const StyledLandingBackgroundColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexBasis: "52%",
  flexDirection: "column",
  justifyContent: "center",
  backgroundOrigin: "content-box",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const StyledMainContentLanding = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "45rem",
  gap: "2rem",
  padding: "20vh 5rem",
}));

export const StyledLandingLoginColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexBasis: "48%",
  padding: "4rem 0 2rem 0",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    flexBasis: "100%",
    padding: "3em 0",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyledLoginArea = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "fit-content",
  flexDirection: "column",
  justifyContent: "center",
  boxSizing: "border-box",
  padding: "0 5rem 0 0",
  [theme.breakpoints.down("lg")]: {
    padding: "0 4rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0 2rem",
  },
}));

export const StyledCenteredMobileText = styled(Typography)(({ theme }) => ({
  marginLeft: "2rem",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0",
    maxWidth: "16rem",
    textAlign: "center",
    display: "flex",
    alignSelf: "center",
  },
}));
