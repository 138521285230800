import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { ConsultationInfo } from "../../customTypes";
import DropddownSortBy from "./DropdownSortBy";
import EnhancedTable from "./table/EnhancedTable";

export default function OpenCases() {
  const [selectedSortBy, setSelectedSortBy] =
    useState<keyof ConsultationInfo>("createdTimestamp");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        paddingBottom: "4rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "150%",
            fontWeight: "600",
          }}
        >
          {t("expiringCases")}
        </Typography>
        <DropddownSortBy
          selectedSortBy={selectedSortBy}
          setSelectedSortBy={setSelectedSortBy}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
        }}
      >
        <EnhancedTable
          presetTypeFilter="Anamnesis"
          rowsPerPageNo={3}
          title={t("unfinishedAnamnesis")}
          selectedSortBy={selectedSortBy}
          setSelectedSortBy={setSelectedSortBy}
          showAll
        />
        <EnhancedTable
          presetTypeFilter="Summary"
          rowsPerPageNo={3}
          title={t("unfinishedSummaries")}
          selectedSortBy={selectedSortBy}
          setSelectedSortBy={setSelectedSortBy}
          showAll
        />
      </Box>
    </Box>
  );
}
