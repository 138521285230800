import { createContext } from "react";
import { Consultation } from "../../customTypes";

export type ConsultationContextType = {
    consultation?: Consultation;
    setConsultation: (consultation: Consultation) => void;
    update: (payload: any) => void;
    transcribeAndThenSummarize: () => Promise<void>;
    summarize: () => Promise<void>;
    createSummary: () => Promise<void>;
    getConsultation: () => Promise<Consultation | undefined>;
    isCurrentlyPolling: boolean;
    setIsCurrentlyPolling: (isCurrentlyPolling: boolean) => void;
    pollSummary: (consultationCreatedTimestamp: string) => Promise<void>;
    setIsLoading: (isLoading: boolean) => void;
};

export const ConsultationContext = createContext<ConsultationContextType>({
    consultation: undefined,
    setConsultation: () => {},
    update: () => {},
    transcribeAndThenSummarize: () => new Promise(() => {}),
    summarize: () => new Promise(() => {}),
    createSummary: () => new Promise(() => {}),
    getConsultation: () => new Promise(() => {}),
    isCurrentlyPolling: false,
    setIsCurrentlyPolling: () => {},
    pollSummary: () => new Promise(() => {}),
    setIsLoading: () => {},
});
