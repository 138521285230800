import { Box, Typography } from "@mui/material";
import { BlueButton } from "../../styles/Buttons.styles";
import { downloadAdiuBestPractice, downloadConsentForm } from "../../utils";

export default function DownloadCenter() {
  const items = [
    {
      name: "Einwilligungserklaerung",
      func: downloadConsentForm,
    },
    {
      name: "Best practice",
      func: downloadAdiuBestPractice,
    },
  ];
  function DownloadItem({ name, func }: { name: string; func: () => void }) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.125rem",
            fontWeight: "600",
          }}
        >
          {name}
        </Typography>
        <Box>
          <BlueButton onClick={func}>Herunterladen</BlueButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: "3rem",
      }}
    >
      {items.map((e, i) => (
        <DownloadItem key={i} name={e.name} func={e.func} />
      ))}
    </Box>
  );
}
