import { Box } from "@mui/material";
import DashboardTopButtons from "./DashboardTopButtons";

type DashboardProps = {
  content: JSX.Element;
  title: string;
};

export default function Dashboard({ content, title }: DashboardProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <DashboardTopButtons title={title} />
      {content}
    </Box>
  );
}
