import { AmplifyUser } from "@aws-amplify/ui";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";

import { t } from "i18next";
import {
  SignInFormStyles,
  StyledSignInButton,
} from "../../styles/SignIn.styles";
import { UserContext } from "../context/UserContext";
import { useDoctorProfile } from "../doctor/custom/useDoctorProfile";
import AuthField from "./AuthField";

type SignInValues = {
  email: string;
  password: string;
};

export default function SignIn() {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [signingIn, setSigningIn] = useState<boolean>(false);
  const [amplifyError, setAmplifyError] = useState<string>();
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get("lang");
  const { getDoctorProfile, createDoctorProfile } = useDoctorProfile();
  const { setDoctorProfile } = useContext(UserContext);

  const signInValidationSchema = object().shape({
    email: string().email("Invalid email").required("Required"),
    password: string().required("Required"),
  });

  useEffect(() => {
    if (lang) {
      localStorage.setItem("language", lang || "en");
    }
  }, [lang]);

  const signIn = async (values: SignInValues) => {
    setAmplifyError("");
    try {
      if (values.email && values.password) {
        await Auth.signIn(values.email.toLowerCase(), values.password).then(
          async (user: AmplifyUser) => {
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
              navigate("/temporary", {
                state: {
                  temporaryPassword: values.password,
                  username: values.email.toLowerCase(),
                },
              });
            } else {
              const doctorProfile = await getDoctorProfile();
              if (!doctorProfile) {
                const { username, name, medicalDomain } = JSON.parse(
                  localStorage.getItem("currentUserSignUp") || ""
                );
                createDoctorProfile(username, name, medicalDomain);
                return;
              }
              if (doctorProfile.needsAdminConfirmation) {
                setDoctorProfile(undefined);
                setUser(undefined);
                localStorage.removeItem("currentUserSignUp");
                navigate("/admin-confirmation");
                return;
              }
              setUser(user);
            }
          }
        );
      }
    } catch (error) {
      setAmplifyError((error as any).message);
    }
    setSigningIn(false);
  };

  const initialFormValues = { email: "", password: "" };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: signInValidationSchema,
    onSubmit: signIn,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={SignInFormStyles}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <AuthField
          formik={formik}
          formikValue="email"
          placeholder="enterYourEmail"
        />

        <AuthField
          formik={formik}
          formikValue="password"
          placeholder="enterYourPassword"
          type="password"
        />

        <Typography
          onClick={() => navigate("/forgotten")}
          sx={{
            cursor: "pointer",
            position: "absolute",
            bottom: "2rem",
            zIndex: "9",
          }}
        >
          {t("forgottenPassword")}
        </Typography>
      </Box>
      {amplifyError && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.error.main,
            fontWeight: "600",
            fontSize: "1.25rem",
            position: "absolute",
            zIndex: "9",
            right: "30%",
            bottom: "0.7rem",
            [theme.breakpoints.down("md")]: {
              left: "2rem",
              fontSize: "1rem",
            },
            [theme.breakpoints.down("sm")]: {
              maxWidth: "7rem",
            },
          })}
        >
          {amplifyError}
        </Typography>
      )}

      <StyledSignInButton
        type="submit"
        onClick={() => {
          if (
            Object.keys(formik.errors).length === 0 &&
            formik.values.email &&
            formik.values.password
          ) {
            setSigningIn(true);
          }
        }}
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {signingIn ? (
          <CircularProgress size={"1.25rem"} style={{ color: "white" }} />
        ) : (
          t("signIn")
        )}
      </StyledSignInButton>
    </Box>
  );
}
