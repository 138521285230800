import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { StyledSignInInput } from "../../styles/SignIn.styles";

export default function AuthField({
  formik,
  formikValue,
  placeholder,
  type = "text",
  isLongError = false,
}: {
  formik: any;
  formikValue: string;
  placeholder: string;
  type?: string;
  isLongError?: boolean;
}) {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        sx={{
          color: theme.palette.grey[300],
          marginBottom: "0.25rem",
        }}
      >
        {t(formikValue)}
      </Typography>
      <StyledSignInInput
        id={formikValue}
        type={type}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={t(placeholder)}
        name={formikValue}
        autoComplete="on"
        variant="outlined"
        value={formik.values[formikValue]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched[formikValue] && Boolean(formik.errors[formikValue])
        }
        helperText={formik.touched[formikValue] && formik.errors[formikValue]}
        InputProps={{
          sx: {
            backgroundColor: "white",
            borderRadius: "40px",
          },
        }}
        isLongError={isLongError}
      />
    </Box>
  );
}
