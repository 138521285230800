import { Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { sendRequest } from "../Api";
import { Instance, Notification } from "../customTypes";
import { convertToJSON } from "../utils";

const transcriptionInstanceId = process.env.REACT_APP_TRANSCRIPTION_INSTANCE_ID;

export default function TopNotification() {
  const [notifications, setNotifications] = useState<Notification[]>();

  const getNotification = () => {
    sendRequest("api/notification", "GET").then((response) => {
      if (!response) return;

      const notification = convertToJSON(response);
      if (notification && notification.display) {
        setNotifications([notification]);
      }
    });
  };

  const getMachineStatus = () => {
    try {
      sendRequest(
        "api/status?instanceId=" + transcriptionInstanceId,
        "GET"
      ).then((result) => {
        if (!result) return;
        const instances: Instance[] = convertToJSON(result)["Reservations"].map(
          (reservation: any) => {
            const i = reservation["Instances"][0];
            const nameTag = i["Tags"].find((tag: any) => tag["Key"] === "Name");
            return {
              name: nameTag ? nameTag["Value"] : "",
              id: i["InstanceId"],
              state: i["State"]["Name"],
            };
          }
        );
        if (instances[0].state === "stopped") {
          if (notifications?.find((n) => n.id === "notification")) return;
          setNotifications((prev) => [
            ...(prev || []),
            {
              id: "notification",
              display: true,
              message: t("machineNotAvailable"),
              type: "warning",
            },
          ]);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNotification();
    getMachineStatus();
  }, []);

  return (
    <Stack
      sx={{
        marginBottom: "1rem",
      }}
      direction={"column"}
      spacing={"1rem"}
    >
      {notifications?.map((notification, idx) => (
        <Box
          key={idx}
          sx={{
            display: "flex",
            backgroundColor:
              notification.type === "error"
                ? "error.main"
                : notification.type === "warning"
                ? "warning.main"
                : "primary.main",
            borderRadius: "40px",
            padding: "1rem 2rem",
            width: "fit-content",
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            {notification.message}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}
