import { Box, Switch, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { sendRequest } from "../../Api";
import { Doctor } from "../../customTypes";
import { BlueButton, GreyButton } from "../../styles/Buttons.styles";
import { checkUserProfileDev, convertToJSON } from "../../utils";
import { UserContextType } from "../context/UserContext";
import PromptComposeSettings from "./PromptComposeSettings";
import SettingsItem from "./SettingsItem";
import StatusSettings from "./StatusSettings";

export default function AdminSettings({
    userContext,
}: {
    userContext: UserContextType;
}) {
    const [doctorsToConfirm, setDoctorsToConfirm] = useState<Doctor[]>([]);
    const { doctorProfile, user, setDoctorProfile } = userContext;

    useEffect(() => {
        if (doctorProfile && checkUserProfileDev(user)) {
            getDoctorsToConfirm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doctorProfile]);

    const getDoctorsToConfirm = () => {
        sendRequest("api/confirmation", "GET").then((result) => {
            if (!result) return;
            const dtc = convertToJSON(result);
            setDoctorsToConfirm(dtc);
        });
    };

    const [isDevUserChecked, setIsDevUserChecked] = useState(
        doctorProfile?.isDevUser
    );

    const handleDevUserCheckChange = (event: any) => {
        const checked = event.target.checked;
        sendRequest(
            "api/isDevUser",
            "PUT",
            JSON.stringify({
                isDevUser: checked,
            })
        ).then((result) => {
            if (!result) return;
            const d: Doctor = convertToJSON(result);
            setDoctorProfile(d);
            setIsDevUserChecked(checked);
        });
    };

    return doctorProfile ? (
        <>
            <SettingsItem label="isDevUser">
                <Switch
                    id={"isDevUser"}
                    name={"isDevUser"}
                    checked={isDevUserChecked}
                    onChange={handleDevUserCheckChange}
                />
            </SettingsItem>
            <StatusSettings />
            <SettingsItem label="pendingDoctors">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5rem",
                    }}
                >
                    {doctorsToConfirm.map((doctor, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        width: "15rem",
                                    }}
                                >
                                    {doctor.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        width: "15rem",
                                    }}
                                >
                                    {doctor.email}
                                </Typography>
                                <Typography
                                    sx={{
                                        width: "15rem",
                                    }}
                                >
                                    {doctor.medicalDomain}
                                </Typography>
                            </Box>

                            <BlueButton
                                onClick={() => {
                                    sendRequest(
                                        "api/confirmation",
                                        "POST",
                                        JSON.stringify({
                                            doctors: [
                                                {
                                                    id: doctor.id,
                                                    email: doctor.email,
                                                },
                                            ],
                                        })
                                    ).then((result) => {
                                        getDoctorsToConfirm();
                                    });
                                }}
                            >
                                {t("confirm")}
                            </BlueButton>
                            <GreyButton
                                onClick={() => {
                                    const confirmDelete = window.confirm(
                                        `${t("areYouSure")} ${t("thisUser")}?`
                                    );
                                    if (confirmDelete) {
                                        sendRequest(
                                            "api/confirmation",
                                            "DELETE",
                                            JSON.stringify({
                                                doctors: [
                                                    {
                                                        id: doctor.id,
                                                        email: doctor.email,
                                                    },
                                                ],
                                            })
                                        ).then((result) => {
                                            getDoctorsToConfirm();
                                        });
                                    }
                                }}
                            >
                                {t("reject")}
                            </GreyButton>
                        </Box>
                    ))}
                </Box>
            </SettingsItem>
            <PromptComposeSettings />
        </>
    ) : (
        <></>
    );
}
