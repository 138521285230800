import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, useTheme } from "@mui/material";
import { useRef } from "react";
import {
  convertToFullSearchDate,
  convertToHHMM,
  getConsultationStatusLabel,
  getTimeLeftHHMM,
  getTimeLeftInSeconds,
  getValueOrEmpty,
} from "../../utils";
import { ConsultationInfo } from "../../customTypes";

type ConsultationFilterProps = {
  setFilteredConsultations: (filteredConsultations: ConsultationInfo[]) => void;
  consultations: ConsultationInfo[];
};

export default function Search({
  setFilteredConsultations,
  consultations,
}: ConsultationFilterProps) {
  const searchRef = useRef<any>();
  const theme = useTheme();

  const filter = (filterText?: string) => {
    const lowerFilterText = filterText?.toLowerCase();
    if (!filterText || !lowerFilterText) {
      setFilteredConsultations(consultations);
    } else {
      setFilteredConsultations(
        consultations.filter((c) => {
          const queryString =
            getValueOrEmpty(c.consultationName) +
            convertToFullSearchDate(c.createdTimestamp) +
            getConsultationStatusLabel(c.consultationStatus, c.presetType) +
            convertToHHMM(c.recordingDuration) +
            getTimeLeftHHMM(getTimeLeftInSeconds(c.ttl));

          return queryString.toLowerCase().includes(lowerFilterText);
        })
      );
    }
  };

  const filterOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      filter((e.target as HTMLInputElement).value);
    }
  };

  const filterOnClick = () => {
    if (searchRef.current?.value) {
      filter(searchRef.current.value);
    }
  };

  return (
    <TextField
      id="filter"
      name="filter"
      variant="outlined"
      sx={{
        width: "17rem",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: theme.palette.grey[300],
          },
        },
      }}
      inputRef={searchRef}
      onKeyDown={filterOnEnter}
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              onClick={filterOnClick}
              sx={{
                cursor: "pointer",
              }}
              style={{ color: theme.palette.grey[500] }}
            />
          </InputAdornment>
        ),
        sx: {
          height: "2.25rem",
          width: "17rem",
          backgroundColor: "white",
          input: {
            "&::placeholder": {
              color: theme.palette.grey[300],
              opacity: "1",
            },
          },
        },
      }}
    />
  );
}
