import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { BlueButton } from "../../styles/Buttons.styles";
import { GradientDivider } from "../../styles/StyledUtils.styles";
import { downloadConsentForm } from "../../utils";

export default function ConsentForm() {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.25rem",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "1.125rem",
          }}
        >
          {t("consentFormDownload")}
        </Typography>
        <Typography>{t("consentFormDownloadDescription")}</Typography>
      </Box>
      <GradientDivider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BlueButton
          sx={{
            width: "fit-content",
          }}
          onClick={downloadConsentForm}
        >
          {t("consentFormDownloadButton")}
        </BlueButton>
      </Box>
    </Box>
  );
}
