import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useContext, useState } from "react";
import { BlueButton } from "../../styles/Buttons.styles";
import { capitalizeFirstLetter, getDaysLeft } from "../../utils";
import PaymentEmbedding from "../PaymentEmbedding";
import CardTopping from "../consultation/utils/CardTopping";
import { UserContext } from "../context/UserContext";
import CardBottom from "../custom/CardBottom";

export default function Card({
  type,
  title,
  body,
  currentlySelected,
}: {
  type: string;
  title: string;
  body: string[];
  currentlySelected: boolean;
}) {
  const { doctorProfile } = useContext(UserContext);
  const theme = useTheme();
  const textColor = currentlySelected ? "white" : theme.palette.text.primary;
  const bgColor = currentlySelected ? theme.palette.text.primary : "white";
  const ableToUpgrade =
    doctorProfile?.license.type === "trial" && type !== "trial";

  const [showPaymentEmbedding, setShowPaymentEmbedding] = useState(false);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "25rem",
          position: "relative",
        }}
      >
        {currentlySelected && (
          <Box
            sx={{
              position: "absolute",
              top: "-1rem",
              right: "0",
              backgroundColor: theme.palette.primary[700],
              width: "6.25rem",
              height: "6.25rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transform: "rotate(12deg)",
              zIndex: "9999",
              borderRadius: "6.1875rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {t("currentPlan")}
            </Typography>
          </Box>
        )}

        <CardTopping backgroundColor={bgColor}>
          <Typography
            sx={{
              color: textColor,
              fontSize: "1.125rem",
              fontWeight: "600",
            }}
          >
            {capitalizeFirstLetter(type)}
          </Typography>
        </CardTopping>
        <Box
          sx={{
            backgroundColor: bgColor,
            width: "100%",
            borderRadius: ableToUpgrade ? "0 40px 40px 0" : "0 40px 40px 40px",
            padding: "0 2rem 2rem 2rem",
          }}
        >
          <Typography
            sx={{
              color: textColor,
              fontSize: "3.75rem",
            }}
          >
            {title}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {body.map((text, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.75rem",
                }}
              >
                <Box
                  component="img"
                  src="svg/greenCircleCheck.svg"
                  sx={{
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                />
                <Typography
                  sx={{
                    color: textColor,
                  }}
                >
                  {text}
                </Typography>
              </Box>
            ))}
          </Box>
          {type === "trial" && doctorProfile?.license.type === "trial" && (
            <Typography
              sx={{
                marginTop: "2rem",
                fontSize: "1.125rem",
                fontWeight: "600",
                color: theme.palette.error.main,
              }}
            >
              {getDaysLeft(doctorProfile?.license.expiryDate)} {t("daysLeft")}
            </Typography>
          )}
        </Box>
        {ableToUpgrade && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "4rem",
            }}
          >
            <CardBottom color="white" right="120px" />
            <BlueButton
              sx={{
                marginLeft: "auto",
                marginTop: "auto",
                padding: "0",
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                setShowPaymentEmbedding(true);
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                }}
              >
                {doctorProfile?.license.type === "trial"
                  ? t("upgrade")
                  : t("change")}
              </Typography>
            </BlueButton>
          </Box>
        )}
      </Box>
      {showPaymentEmbedding && (
        <PaymentEmbedding
          setShowPaymentEmbedding={setShowPaymentEmbedding}
          type={type}
        />
      )}
    </>
  );
}
