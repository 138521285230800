import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import ReactDOM from "react-dom/client";
import App from "./App";
import awsconfig from "./aws-exports";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

reportWebVitals();
