import { Box, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useState } from "react";
import { sendRequest } from "../Api";
import { DEFAULT_TRANSITION } from "../utils";
import { UserContext } from "./context/UserContext";

type FeedbackValues = {
  feedbackText: string;
};

export default function Feedback() {
  const [result, setResult] = useState<string>("");
  const { doctorProfile } = useContext(UserContext);
  const theme = useTheme();

  const submitFeedback = async (values: FeedbackValues) => {
    try {
      if (values.feedbackText) {
        sendRequest(
          "api/feedback",
          "POST",
          JSON.stringify({
            userEmail: doctorProfile?.email,
            feedbackText: values.feedbackText,
          })
        ).then((result) => {
          if (result) setResult("Feedback submitted");
          formik.resetForm();
        });
      }
    } catch (error) {
      console.log("Error submitting", error);
    }
  };

  const initialFeedbackValues = { feedbackText: "" };

  const formik = useFormik({
    initialValues: initialFeedbackValues,
    onSubmit: submitFeedback,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        paddingRight: "1rem",
        paddingLeft: "1rem",
        gap: "0.75rem",
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Typography
        sx={{
          fontWeight: "600",
        }}
      >
        {t("feedback")}
      </Typography>
      <Typography
        sx={{
          fontSize: "70%",
        }}
      >
        {t("feedbackDescription")}
      </Typography>
      {result ? (
        <Typography
          sx={{
            fontSize: "75%",
          }}
        >
          {result}
        </Typography>
      ) : (
        <>
          <TextField
            id="feedbackText"
            name="feedbackText"
            autoComplete="off"
            variant="outlined"
            value={formik.values.feedbackText}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.feedbackText && Boolean(formik.errors.feedbackText)
            }
            multiline
            rows={3}
            sx={{
              fontSize: "40%",
              "& .MuiInputBase-root": {
                borderRadius: "0.75rem",
                height: "100%",
              },
              "& label.Mui-focused": {
                borderWidth: "0.5px",
              },
              "& .MuiInput-underline:after": {
                borderWidth: "0.5px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderWidth: "0.5px",
                },
                "&:hover fieldset": {
                  borderWidth: "0.5px",
                },
                "&.Mui-focused fieldset": {
                  borderWidth: "0.5px",
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: "200%",
                padding: "0.5rem",
                backgroundColor: "white",
              },
            }}
          />
          <Typography
            sx={{
              fontSize: "75%",
              cursor: "pointer",
              fontWeight: "600",
              color: (theme as any).palette.primary[700],
              width: "3rem",
              ...DEFAULT_TRANSITION,
              "&:hover": {
                color: theme.palette.text.primary,
              },
            }}
            onClick={() => formik.handleSubmit()}
          >
            {t("send")}
          </Typography>
        </>
      )}
    </Box>
  );
}
