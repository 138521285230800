import { Box, Typography, useTheme } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { sendRequest } from "../../Api";
import { Instance } from "../../customTypes";
import {
  DarkTransparentButton,
  WhiteButton,
} from "../../styles/Buttons.styles";
import { checkUserProfileDev, convertToJSON } from "../../utils";
import { UserContext } from "../context/UserContext";
import SettingsItem from "./SettingsItem";

const transcriptionInstanceId = process.env.REACT_APP_TRANSCRIPTION_INSTANCE_ID;

export default function StatusSettings({ isSidebar }: { isSidebar?: boolean }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [instances, setInstances] = useState<Instance[]>([]);
  const { doctorProfile, user } = useContext(UserContext);

  useEffect(() => {
    if (doctorProfile && checkUserProfileDev(user)) {
      getStatuses();
    }
  }, [doctorProfile]);

  const getStatuses = () => {
    sendRequest("api/status?instanceId=" + transcriptionInstanceId, "GET").then(
      (result) => {
        if (!result) return;
        const runningInstances: Instance[] = convertToJSON(result)[
          "Reservations"
        ].map((reservation: any) => {
          const i = reservation["Instances"][0];
          const nameTag = i["Tags"].find((tag: any) => tag["Key"] === "Name");
          return {
            name: nameTag ? nameTag["Value"] : "",
            id: i["InstanceId"],
            state: i["State"]["Name"],
          };
        });
        setInstances(runningInstances);
      }
    );

    setIsLoading(false);
  };
  const buttons = [
    {
      name: "start",
      button: (
        <WhiteButton
          onClick={async () => {
            await sendRequest(
              "api/services",
              "POST",
              JSON.stringify({
                instances: instances.map((i) => i.id),
              })
            );
            setTimeout(() => {
              getStatuses();
            }, 3000);
          }}
        >
          Start
        </WhiteButton>
      ),
    },
    {
      name: "stop",
      button: (
        <DarkTransparentButton
          onClick={async () => {
            await sendRequest(
              "api/services",
              "DELETE",
              JSON.stringify({
                instances: instances.map((i) => i.id),
              })
            );
            setTimeout(() => {
              getStatuses();
            }, 3000);
          }}
        >
          Stop
        </DarkTransparentButton>
      ),
    },
  ];

  const getInstanceButtons = useCallback(() => {
    if (!doctorProfile || !instances || instances.length === 0) return;

    const buttonsToShow: any = [];
    if (
      instances.filter((i) => i.state === "pending" || i.state === "stopping")
        .length === 0
    ) {
      if (instances.filter((i) => i.state === "running").length < 1) {
        buttonsToShow.push(
          ...buttons.filter((button) => button.name.includes("start"))
        );
      }
    }
    buttonsToShow.push(buttons.find((button) => button.name === "stop"));

    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {buttonsToShow.map((button, idx) => (
          <Box key={idx}>{button.button}</Box>
        ))}
      </Box>
    );
  }, [instances]);
  return isSidebar ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5rem",
        marginBottom: "1rem",
      }}
    >
      <Typography>Manage</Typography>
      <Box>{getInstanceButtons()}</Box>
      <Typography>Status</Typography>
      {instances.map((instance, index) => {
        if (instance) {
          return (
            <Typography
              sx={{
                backgroundColor:
                  instance.state === "running"
                    ? theme.palette.success.main
                    : instance.state === "stopped"
                    ? theme.palette.error.main
                    : theme.palette.warning.main,
                borderRadius: "40px",
                padding: "0.5rem 1rem",
                color: "white",
                width: "7rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={index}
            >
              {instance.state[0].toUpperCase() + instance.state.slice(1)}
            </Typography>
          );
        }
      })}
    </Box>
  ) : (
    <>
      <SettingsItem label="Services">{getInstanceButtons()}</SettingsItem>
      <SettingsItem label="Service Statuses">
        {instances.map((instance, index) => {
          if (instance) {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    width: "15rem",
                  }}
                >
                  {instance.name}
                </Typography>
                <Typography
                  sx={{
                    backgroundColor:
                      instance.state === "running"
                        ? theme.palette.success.main
                        : instance.state === "stopped"
                        ? theme.palette.error.main
                        : theme.palette.warning.main,
                    borderRadius: "40px",
                    padding: "0.5rem 1rem",
                    color: "white",
                    width: "7rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {instance.state[0].toUpperCase() + instance.state.slice(1)}
                </Typography>
              </Box>
            );
          }
        })}
      </SettingsItem>
    </>
  );
}
