import LogoDevIcon from "@mui/icons-material/LogoDev";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarButton } from "../styles/Buttons.styles";
import { GradientDivider } from "../styles/StyledUtils.styles";
import { DEFAULT_TRANSITION, checkUserProfileDev, getDaysLeft } from "../utils";
import Feedback from "./Feedback";
import Loading from "./Loading";
import { useConsultation } from "./consultation/custom/useConsultation";
import { UserContext } from "./context/UserContext";
import InitialUserAvatar from "./doctor/InitialUserAvatar";
import { useDoctorProfile } from "./doctor/custom/useDoctorProfile";
import MySubscription from "./settings/MySubscription";
import StatusSettings from "./settings/StatusSettings";

const SIDEBAR_FONT_WEIGHT = "600";

export default function Sidebar() {
  const { setUser, doctorProfile, avatar, user } = useContext(UserContext);
  const { createConsultation } = useConsultation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { getDoctorProfile } = useDoctorProfile();

  const [clickedNewRecording, setClickedNewRecording] = useState(false);

  const daysLeft = getDaysLeft(doctorProfile?.license.expiryDate);

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUser(undefined);
      navigate("/");
    } catch (error) {
      console.log("error signing out", error);
    }
  };

  useEffect(() => {
    getDoctorProfile();
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "17.5rem",
          boxSizing: "border-box",
          backgroundColor: theme.palette.secondary.main,
          padding: "3.1rem 1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          overflowY: "scroll",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          component="img"
          src="/svg/Adiu_Logo_beta.svg"
          sx={{
            width: "120px",
            marginLeft: "0.75rem",
            marginBottom: "1.5rem",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            marginLeft: "0.25rem",
            marginBottom: "2rem",
          }}
        >
          <SidebarButton
            currentlySelected={location.pathname === "/"}
            onClick={() => navigate("/")}
          >
            <Box component="img" src="/svg/overview.svg" />
            <Typography
              sx={{
                fontWeight: SIDEBAR_FONT_WEIGHT,
              }}
            >
              {t("overview")}
            </Typography>
          </SidebarButton>

          <SidebarButton
            currentlySelected={location.pathname === "/consultation"}
            onClick={async () => {
              setClickedNewRecording(true);
              navigate(`/consultation`);
              const c = await createConsultation();
              navigate(`/consultation/${c.createdTimestamp}`);
              setClickedNewRecording(false);
            }}
          >
            {clickedNewRecording ? (
              <CircularProgress
                size={"1.5rem"}
                style={{ color: theme.palette.text.primary }}
              />
            ) : (
              <Box component="img" src="/svg/createNew.svg" />
            )}
            <Typography
              sx={{
                fontWeight: SIDEBAR_FONT_WEIGHT,
              }}
            >
              {t("createNew")}
            </Typography>
          </SidebarButton>
          <SidebarButton
            currentlySelected={location.pathname === "/open"}
            onClick={() => navigate("/open")}
          >
            <Box component="img" src="/svg/openCases.svg" />
            <Typography
              sx={{
                fontWeight: SIDEBAR_FONT_WEIGHT,
              }}
            >
              {t("openCases")}
            </Typography>
          </SidebarButton>
          <SidebarButton
            currentlySelected={false}
            onClick={() => {
              window.location.href = "/mobile";
            }}
          >
            <PhoneAndroidIcon />
            <Typography
              sx={{
                fontWeight: SIDEBAR_FONT_WEIGHT,
              }}
            >
              {t("mobile")}
            </Typography>
          </SidebarButton>
        </Box>
        <Box
          sx={{
            marginTop: "auto",
          }}
        >
          {checkUserProfileDev(user) && (
            <>
              <GradientDivider />
              <StatusSettings isSidebar />
            </>
          )}
          <GradientDivider />
          <Feedback />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginLeft: "0.25rem",
              marginTop: "2rem",
            }}
          >
            {doctorProfile?.license.type === "trial" && (
              <SidebarButton
                currentlySelected={false}
                onClick={() => {
                  navigate("/settings", {
                    state: "MY_SUBSCRIPTION",
                  });
                }}
              >
                <Box component="img" src="/svg/euro.svg" />
                <Typography
                  sx={{
                    fontWeight: SIDEBAR_FONT_WEIGHT,
                  }}
                >
                  Trial
                </Typography>
                <Typography
                  sx={{
                    color:
                      daysLeft > 0
                        ? theme.palette.primary[700]
                        : theme.palette.error.main,
                    fontWeight: SIDEBAR_FONT_WEIGHT,
                  }}
                >
                  {daysLeft} {t("daysLeft")}
                </Typography>
              </SidebarButton>
            )}

            <SidebarButton
              currentlySelected={location.pathname === "/help"}
              onClick={() => navigate("/help")}
            >
              <Box component="img" src="/svg/support.svg" />
              <Typography
                sx={{
                  fontWeight: SIDEBAR_FONT_WEIGHT,
                }}
              >
                {t("helpPage")}
              </Typography>
            </SidebarButton>
            <SidebarButton
              currentlySelected={location.pathname === "/settings"}
              onClick={() => navigate("/settings")}
            >
              <Box component="img" src="/svg/settings.svg" />
              <Typography
                sx={{
                  fontWeight: SIDEBAR_FONT_WEIGHT,
                }}
              >
                {t("settings")}
              </Typography>
            </SidebarButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "0.75rem",
              marginTop: "4rem",
              width: "100%",
            }}
          >
            {doctorProfile ? (
              <>
                {avatar && avatar.length > 0 ? (
                  <Box
                    component="img"
                    src={avatar}
                    sx={{
                      width: "2rem",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <InitialUserAvatar />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: doctorProfile.email ? "75%" : "100%",
                      fontWeight: SIDEBAR_FONT_WEIGHT,
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {doctorProfile.name}
                    {doctorProfile.isDevUser && <LogoDevIcon color="error" />}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "75%",
                    }}
                  >
                    {doctorProfile.email?.split("@")[0]}
                  </Typography>
                </Box>
              </>
            ) : (
              <Loading size="3rem" />
            )}

            <Box
              component="img"
              src="/svg/signOut.svg"
              onClick={signOut}
              sx={{
                marginLeft: "auto",
                cursor: "pointer",
                padding: "0.5rem",
                borderRadius: "40px",
                ...DEFAULT_TRANSITION,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      {doctorProfile?.license.type === "trial" && daysLeft <= 0 && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: "99999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "80%",
              backgroundColor: theme.palette.secondary.main,
              height: "fit-content",
              padding: "2rem",
              borderRadius: "40px",
            }}
          >
            <MySubscription title="yourCurrentSubscriptionHasEnded" />
          </Box>
        </Box>
      )}
    </>
  );
}
