import { Box, Divider, useTheme } from "@mui/material";

export default function FullLengthDivider({ color }: { color?: string }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Divider
        sx={{
          borderColor: color ? color : theme.palette.secondary.main,
          backgroundColor: color ? color : theme.palette.secondary.main,
          width: "calc(100% + 4rem)",
        }}
      />
    </Box>
  );
}
