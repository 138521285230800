import { Box, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DEFAULT_BOX_SHADOW } from "../../../utils";
import Loading from "../../Loading";
import { ConsultationContext } from "../../context/ConsultationContext";
import SwitchableCardTopping from "../utils/SwitchableCardTopping";
import ConsultationRegenerateContent from "./ConsultationRegenerateContent";
import ConsultationSummaryContent from "./ConsultationSummaryContent";

export default function ConsultationResults() {
    const {
        consultation,
        pollSummary,
        summarize,
        transcribeAndThenSummarize,
        isCurrentlyPolling,
    } = useContext(ConsultationContext);
    const theme = useTheme();
    const [type, setType] = useState<"SUMMARY" | "REGENERATE">("SUMMARY");

    useEffect(() => {
        if (!isCurrentlyPolling && !consultation?.summaries) {
            if (consultation?.currentlyTranscribingAndSummarizing) {
                pollSummary(consultation.createdTimestamp);
            } else if (consultation?.transcribed) {
                summarize();
            } else {
                transcribeAndThenSummarize();
            }
        }
    }, []);

    const renderType = () => {
        switch (type) {
            case "SUMMARY":
                return <ConsultationSummaryContent setType={setType} />;
            case "REGENERATE":
                return <ConsultationRegenerateContent setType={setType} />;
        }
    };
    return !consultation ? (
        <Loading />
    ) : (
        <>
            <Box sx={{ display: "flex", alignItems: "end" }}>
                <SwitchableCardTopping
                    cardType="REGENERATE"
                    label="Regenerate options"
                    type={type}
                    setType={setType}
                />
                <SwitchableCardTopping
                    cardType="SUMMARY"
                    label={consultation.presetType}
                    bothSlim
                    type={type}
                    setType={setType}
                />
            </Box>
            <Box
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    width: "100%",
                    padding: "2rem",
                    borderRadius: "40px",
                    ...DEFAULT_BOX_SHADOW,
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    position: "relative",
                }}
            >
                {renderType()}
            </Box>
        </>
    );
}
