import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DEFAULT_BOX_SHADOW, checkUserProfileDev } from "../../utils";
import Loading from "../Loading";
import SwitchableCardTopping from "../consultation/utils/SwitchableCardTopping";
import { UserContext } from "../context/UserContext";
import { useDoctorProfile } from "../doctor/custom/useDoctorProfile";
import AdminSettings from "./AdminSettings";
import ConsentForm from "./ConsentForm";
import MySubscription from "./MySubscription";
import PromptPresetsSettings from "./PromptPresetsSettings";
import SettingsForm from "./SettingsForm";

export default function Settings() {
  const userContext = useContext(UserContext);
  const { getDoctorProfile } = useDoctorProfile();
  const [type, setType] = useState<string>(useLocation().state || "MY_DETAILS");
  const theme = useTheme();
  const urlParams = new URLSearchParams(window.location.search);
  const [sessionId] = useState<string | null>(urlParams.get("session_id"));

  useEffect(() => {
    getDoctorProfile();
    if (sessionId && type !== "MY_SUBSCRIPTION") {
      setType("MY_SUBSCRIPTION");
    }
  }, []);

  const renderType = () => {
    switch (type) {
      case "MY_DETAILS":
        return <SettingsForm userContext={userContext} />;
      case "PRESETS":
        return <PromptPresetsSettings />;
      case "MY_SUBSCRIPTION":
        return <MySubscription title="subscriptionsOverview" />;
      case "CONSENT_FORM":
        return <ConsentForm />;
      case "ADMIN_SETTINGS":
        return <AdminSettings userContext={userContext} />;
    }
  };

  const cards = [
    {
      label: t("myDetails"),
      type: "MY_DETAILS",
    },
    {
      label: t("mySubscription"),
      type: "MY_SUBSCRIPTION",
    },
    {
      label: t("promptPresets"),
      type: "PRESETS",
    },
    {
      label: t("consentForm"),
      type: "CONSENT_FORM",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",

        width: "100%",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: "150%",
          fontWeight: "600",
        }}
      >
        {t("settings")}
      </Typography>
      {userContext.doctorProfile ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              zIndex: "0",
            }}
          >
            {cards.map((card, idx) => (
              <SwitchableCardTopping
                key={idx}
                cardType={card.type}
                label={card.label}
                bothSlim={idx > 0}
                type={type}
                setType={setType}
              />
            ))}
            {checkUserProfileDev(userContext.user) && (
              <SwitchableCardTopping
                cardType="ADMIN_SETTINGS"
                label={t("adminSettings")}
                bothSlim
                type={type}
                setType={setType}
              />
            )}
          </Box>
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.main,
              height: "100%",
              width: "100%",
              padding: "2rem",
              borderRadius: "40px",
              marginTop: "-2rem",
              ...DEFAULT_BOX_SHADOW,
              overflow: type === "PRESETS" ? "visible" : "auto",
              "::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              position: "relative",
            }}
          >
            {renderType()}
          </Box>
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
