import { useContext, useEffect, useState } from "react";
import { sendRequest } from "../../../Api";
import { Consultation, ConsultationStatus, Doctor } from "../../../customTypes";
import { convertToJSON } from "../../../utils";
import { UserContext } from "../../context/UserContext";
import { useDoctorProfile } from "../../doctor/custom/useDoctorProfile";

export function useConsultation(createdTimestamp?: any, setIsLoading?: any) {
  const { doctorProfile, setDoctorProfile } = useContext(UserContext);
  const [consultation, setConsultation] = useState<Consultation>();
  const [consultationNotFound, setConsultationNotFound] =
    useState<boolean>(false);
  const [isCurrentlyPolling, setIsCurrentlyPolling] = useState<boolean>(false);

  useEffect(() => {
    if (createdTimestamp) {
      getConsultation();
    }
  }, [createdTimestamp]);

  const { getDoctorProfile } = useDoctorProfile();

  useEffect(() => {
    getDoctorProfile();
  }, []);

  const convertAndSetConsultation = (result: string | void) => {
    if (!result) return;
    const c = convertToJSON(result);

    if (!c.summaries && c.summarized) {
      c.summaries = [c.summarized];
    }

    setConsultation(c);
    setIsLoading(false);
    return c;
  };

  const getConsultation = async () => {
    return await sendRequest(
      `api/consultation?createdTimestamp=${createdTimestamp}`,
      "GET"
    )
      .then((result) => convertAndSetConsultation(result))
      .catch(() => {
        setConsultationNotFound(true);
      });
  };

  const longPollSummary = async () => {
    return await sendRequest(
      `api/poll?previousAmountOfSummaries=${
        consultation?.summaries ? consultation.summaries.length : 0
      }&createdTimestamp=${createdTimestamp}`,
      "GET"
    ).then((result) => {
      if (!result) return;
      return convertToJSON(result);
    });
  };

  const getConsultationStatus = (payload: any) => {
    if (consultation) {
      let consultationStatus: ConsultationStatus;
      if (
        ("summaries" in payload && payload["summaries"]) ||
        (!("summaries" in payload) && consultation.summaries)
      ) {
        consultationStatus = "DONE";
      } else if (
        ("transcribed" in payload && payload["transcribed"] !== "") ||
        (!("transcribed" in payload) && consultation.transcribed)
      ) {
        consultationStatus = "TRANSCRIBED";
      } else if (
        ("audio" in payload && payload["audio"] !== "") ||
        (!("audio" in payload) && consultation.audio)
      ) {
        consultationStatus = "RECORDED";
      } else {
        consultationStatus = "NOT_STARTED";
      }
      return consultationStatus;
    } else {
      return "NOT_STARTED";
    }
  };

  const transcribeAndThenSummarize = async () => {
    if (consultation && consultation.audio) {
      try {
        sendRequest(
          "api/transcribe",
          "POST",
          JSON.stringify({
            createdTimestamp: consultation.createdTimestamp,
            audioPath: consultation.audio.split("/").at(-1),
          })
        );
      } catch {}
    }
  };

  const pollSummary = async (consultationCreatedTimestamp: string) => {
    const times = 30;
    const timeoutSeconds = 5;

    setIsCurrentlyPolling(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      for (let i = 0; i < times; i++) {
        if (!window.location.href.includes(consultationCreatedTimestamp)) {
          break;
        }
        const newConsultation = await longPollSummary();
        if (newConsultation && newConsultation.summaries) {
          setConsultation(newConsultation);
          update({
            consultationStatus: "DONE",
            currentlyTranscribingAndSummarizing: false,
          });
          break;
        }
        await new Promise((resolve) =>
          setTimeout(resolve, timeoutSeconds * 1000)
        );
      }
    } catch {}
    setIsCurrentlyPolling(false);
  };

  const createSummary = async () => {
    if (consultation && doctorProfile) {
      sendRequest(
        "api/doctor",
        "PUT",
        JSON.stringify({
          summariesDone: doctorProfile.summariesDone
            ? parseInt(doctorProfile.summariesDone) + 1
            : 1,
        })
      ).then((result) => {
        if (!result) return;
        const d: Doctor = convertToJSON(result);
        setDoctorProfile(d);
      });
      update({
        consultationStep: "SUMMARY",
        currentlyTranscribingAndSummarizing: true,
      });
      if (!consultation.transcribed) {
        await transcribeAndThenSummarize();
      } else {
        await summarize();
      }
    }
  };

  const summarize = async () => {
    try {
      if (consultation && consultation.transcribed) {
        setIsCurrentlyPolling(true);
        await sendRequest(
          "api/summarize",
          "POST",
          JSON.stringify({
            createdTimestamp: consultation.createdTimestamp,
            input: consultation.transcribed,
          })
        ).then((result) => {
          if (!result) return;
          const c: Consultation = convertToJSON(result);
          setConsultation(c);
          if (consultation.currentlyTranscribingAndSummarizing) {
            update({
              currentlyTranscribingAndSummarizing: false,
              consultationStatus: "DONE",
            });
          }
        });
      }
    } catch {}
    setIsCurrentlyPolling(false);
  };

  const update = async (payload: any) => {
    if (consultation && consultation.createdTimestamp) {
      return await sendRequest(
        "api/consultation",
        "PUT",
        JSON.stringify({
          createdTimestamp: consultation.createdTimestamp,
          consultationStatus: getConsultationStatus(payload),
          ...payload,
        })
      ).then((result) => {
        convertAndSetConsultation(result);
      });
    }
  };

  const createConsultation = async (): Promise<Consultation> => {
    return await sendRequest("api/doctor", "GET").then(async (result) => {
      if (!result) return;
      const doctorProfile: Doctor = convertToJSON(result);
      return await sendRequest(
        "api/doctor",
        "PUT",
        JSON.stringify({
          consultationNo: parseInt(doctorProfile.consultationNo) + 1,
        })
      ).then(async () => {
        const body = JSON.stringify({
          consultationName: `Recording #${
            parseInt(doctorProfile.consultationNo) + 1
          }`,
          dev: doctorProfile.isDevUser,
        });
        return await sendRequest("api/consultation", "POST", body).then(
          (result) => {
            if (!result) return;
            return convertToJSON(result);
          }
        );
      });
    });
  };

  const downloadConsultation = (consultation?: Consultation) => {
    if (consultation && doctorProfile) {
      const element = document.createElement("a");
      const fileContent = `${consultation.consultationName}

Doctor:
${doctorProfile.name}
${doctorProfile.email}

Transcript: {
${consultation.transcribed || ""}
}

Summaries: {
${consultation.summaries?.join("\n\n\n\n") || ""}
}`;
      const file = new Blob([fileContent], {
        type: "text/plain;charset=utf-8",
      });
      element.href = URL.createObjectURL(file);
      element.download = `${consultation.consultationName}.txt`;
      document.body.appendChild(element);
      element.click();
    }
  };

  return {
    consultation,
    convertAndSetConsultation,
    setConsultation,
    update,
    transcribeAndThenSummarize,
    summarize,
    createSummary,
    getConsultation,
    isCurrentlyPolling,
    setIsCurrentlyPolling,
    pollSummary,
    createConsultation,
    downloadConsultation,
    consultationNotFound,
  };
}
