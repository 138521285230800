import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext } from "react";
import { ConsultationContext } from "../../context/ConsultationContext";
import UpdateableField from "../utils/UpdateableField";
import UploadBox from "./UploadBox";

export default function UploadTextContent() {
  const { update, consultation } = useContext(ConsultationContext);

  const uploadTranscript = async (textFile: File) => {
    let text = await textFile.text();
    if (text.includes("Transcript: {")) {
      text = text.split("Transcript: {")[1].split("}")[0].trim();
    }
    update({ transcribed: text });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        gap: "2rem",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
          width: "100%",
          alignItems: "center",
        }}
      >
        <UploadBox upload={uploadTranscript} />
        <Box
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            padding: "2rem",
            gap: "0.5rem",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            {t("uploadTextFileDescription")}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "18px",
        }}
      >
        {t("alternativelyYouCanAlso")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
          alignItems: "center",
          width: "100%",
        }}
      >
        <UpdateableField
          sx={{
            width: "60%",
            "& .MuiInputBase-root": {
              height: "100%",
            },
          }}
          name="transcribed"
          update={update}
          defaultValue={consultation?.transcribed || ""}
          placeholder={t("copyTextHere")}
        />
        <Typography sx={{ width: "40%" }}>
          {t("copyPasteTranscriptText")}
        </Typography>
      </Box>
    </Box>
  );
}
