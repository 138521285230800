import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { ConsultationInfo } from "../../customTypes";
import { TransparentButton } from "../../styles/Buttons.styles";
import { DEFAULT_BOX_SHADOW, DEFAULT_TRANSITION } from "../../utils";

type Sort = {
  label: string;
  property: keyof ConsultationInfo;
};

export default function DropddownSortBy({
  selectedSortBy,
  setSelectedSortBy,
}: {
  selectedSortBy: keyof ConsultationInfo;
  setSelectedSortBy: (filter: keyof ConsultationInfo) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const backgroundColorOpen = "white";

  const buttonStyles = isOpen
    ? {
        backgroundColor: backgroundColorOpen,
        color: theme.palette.text.primary,
        borderRadius: "20px 20px 0 0",
        border: "0.5px solid rgba(0, 0, 0, 0)",
        outline: "0",
      }
    : {
        backgroundColor: "transparent",
        borderRadius: "",
        border: "",
      };

  const dropDownStyles = isOpen
    ? {
        top: "2.25rem",
        borderRadius: "20px 0 20px 20px",
        zIndex: "99999",
        ...DEFAULT_BOX_SHADOW,
      }
    : {
        top: "0rem",
        borderRadius: "20px",
        opacity: "0",
        zIndex: "-1",
      };

  const sorters: Sort[] = [
    { label: t("fileName"), property: "consultationName" },
    { label: t("dateAndTimeCreated"), property: "createdTimestamp" },
    { label: t("durationOfRecording"), property: "recordingDuration" },
    { label: t("patientIdentifier"), property: "patient" },
    { label: t("statusOfProgress"), property: "consultationStatus" },
    { label: t("timeLeft"), property: "ttl" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", position: "relative" }}>
        <Box
          sx={{
            width: "15px",
            height: "15px",
            position: "absolute",
            left: isOpen ? "-15px" : "0",
            bottom: "0",
            backgroundImage: `radial-gradient(circle at 100% 0, transparent 0%, transparent 15px, ${backgroundColorOpen} 15px);`,
            transform: "scale(-1, 1)",
            opacity: isOpen ? "1" : "0",
            ...DEFAULT_TRANSITION,
          }}
        />
        <TransparentButton
          sx={{
            gap: "0.5rem",
            height: "3rem",
          }}
          style={buttonStyles}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {t("sortBy")}
          <KeyboardArrowDownIcon
            sx={{
              transform: isOpen ? "scaleY(-1);" : "none",
              transition: "transform 150ms linear",
            }}
          />
        </TransparentButton>
      </Box>
      <Box
        sx={{
          backgroundColor: backgroundColorOpen,
          position: "absolute",
          right: "0",
          ...dropDownStyles,
          padding: "1rem",
          width: "14rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          ...DEFAULT_TRANSITION,
        }}
      >
        {sorters.map((sorter) => (
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
              cursor: "pointer",
              width: "fit-content",
              ...DEFAULT_TRANSITION,
              opacity: isOpen ? "1" : "0",
            }}
            key={sorter.property}
            onClick={() => {
              setSelectedSortBy(sorter.property);
              setIsOpen(false);
            }}
          >
            <Box
              component="img"
              src={`/svg/dropdownFilters/${sorter.property}.svg`}
              sx={{
                width: "1.5rem",
                ...DEFAULT_TRANSITION,
                filter:
                  selectedSortBy === sorter.property
                    ? "brightness(0) saturate(100%) invert(85%) sepia(21%) saturate(5249%) hue-rotate(178deg) brightness(102%) contrast(105%)"
                    : "brightness(0) saturate(100%) invert(16%) sepia(10%) saturate(3261%) hue-rotate(194deg) brightness(98%) contrast(92%)",
              }}
            />

            <Typography
              sx={{
                ...DEFAULT_TRANSITION,
                color:
                  selectedSortBy === sorter.property
                    ? theme.palette.primary[700]
                    : theme.palette.text.primary,
              }}
            >
              {sorter.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
