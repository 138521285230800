import { Box, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import {
  SignInFormStyles,
  StyledSignInButton,
  StyledSignInInput,
} from "../../styles/SignIn.styles";

type ForgottenPasswordValues = {
  email: string;
};

export default function ForgottenPassword() {
  const theme = useTheme();
  const navigate = useNavigate();
  const forgottenPasswordValidationSchema = object().shape({
    email: string().email("Invalid email").required(t("required")),
  });

  const forgottenPassword = async (values: ForgottenPasswordValues) => {
    try {
      if (values.email) {
        await Auth.forgotPassword(values.email.toLowerCase()).then(
          (response) => {
            navigate("/code", { state: values.email.toLowerCase() });
          }
        );
      }
    } catch (error) {}
  };

  const initialFormValues = { email: "" };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: forgottenPasswordValidationSchema,
    onSubmit: forgottenPassword,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={SignInFormStyles}>
      <Box>
        <Typography
          sx={{
            color: theme.palette.grey[300],
            marginBottom: "0.25rem",
          }}
        >
          Email
        </Typography>
        <StyledSignInInput
          id="email"
          placeholder={t("enterYourEmail")}
          name="email"
          type="email"
          autoComplete="on"
          variant="outlined"
          value={formik.values.email.toLowerCase()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Box>

      <StyledSignInButton
        type="submit"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        sx={{
          marginTop: "3.65rem",
        }}
      >
        Send
      </StyledSignInButton>
    </Box>
  );
}
