import { Box, Typography } from "@mui/material";
import { getTimeLeftHHMM, getTimeLeftInSeconds } from "../../../../utils";

type TimeLeftProps = {
  ttl?: string;
};

export default function TimeLeft({ ttl }: TimeLeftProps) {
  const timeLeft = getTimeLeftInSeconds(ttl);
  return timeLeft ? (
    <Box
      sx={{
        display: "flex",
        gap: "0.75rem",
      }}
    >
      <Box
        component="img"
        src={`/svg/clock${
          timeLeft > 10800 ? "Green" : timeLeft > 5400 ? "Orange" : "Red"
        }.svg`}
      />
      <Typography
        sx={{
          fontSize: "14px",
        }}
      >{`${getTimeLeftHHMM(timeLeft)} h`}</Typography>
    </Box>
  ) : (
    <></>
  );
}
