import { Box, CircularProgress } from "@mui/material";

type LoadingProps = {
  size?: string;
  color?: string;
};

export default function Loading({ size = "10rem", color }: LoadingProps) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size={size}
        sx={
          color
            ? {
                color: color,
              }
            : {}
        }
      />
    </Box>
  );
}
