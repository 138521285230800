import { sendRequest } from "../../../Api";
import { Preset } from "../../../customTypes";
import { convertToJSON } from "../../../utils";

export function usePresets(
  setPresets: any,
  type: string,
  setCurrentlySelectedPreset?: any
) {
  const getPresets = async () => {
    const p: Preset[] = await sendRequest("api/presets", "GET").then((r) => {
      if (!r) return;
      return convertToJSON(r);
    });
    if (p.length > 0) {
      setPresets(p);
      if (setCurrentlySelectedPreset) {
        setCurrentlySelectedPreset(p.find((x) => x.type === type));
      }
    } else {
      const presets: Preset[] = await sendRequest("api/presets", "POST").then(
        (r) => {
          if (!r) return;
          return convertToJSON(r);
        }
      );
      setPresets(presets);
      if (setCurrentlySelectedPreset) {
        setCurrentlySelectedPreset(presets[0]);
      }
    }
  };
  return { getPresets };
}
