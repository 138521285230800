import { AmplifyUser } from "@aws-amplify/ui";
import { createContext } from "react";
import { Doctor } from "../../customTypes";

export type UserContextType = {
  user?: AmplifyUser;
  setUser: (user: AmplifyUser | undefined) => void;
  doctorProfile?: Doctor;
  setDoctorProfile: React.Dispatch<React.SetStateAction<Doctor | undefined>>;
  avatar?: string;
  setAvatar: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const UserContext = createContext<UserContextType>({
  user: undefined,
  setUser: () => {},
  doctorProfile: undefined,
  setDoctorProfile: () => {},
  avatar: undefined,
  setAvatar: () => {},
});
