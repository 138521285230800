import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Doctor } from "../../../customTypes";
import { TransparentButton } from "../../../styles/Buttons.styles";
import { GradientDivider } from "../../../styles/StyledUtils.styles";
import {
  DEFAULT_BOX_SHADOW,
  formatMinutesToHoursAndMinutes,
} from "../../../utils";
import CardBottom from "../../custom/CardBottom";

export default function HoursSaved({
  doctorProfile,
}: {
  doctorProfile: Doctor;
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const getHoursSaved = () => {
    if (!doctorProfile.summariesDone) return "0 min";
    const minutesSaved = parseInt(doctorProfile?.summariesDone) * 4;
    return formatMinutesToHoursAndMinutes(minutesSaved);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "1.5rem",
        height: "13.75rem",
      }}
    >
      <Box
        sx={{
          width: "65%",
          height: "100%",
          backgroundColor: theme.palette.grey[100],
          borderRadius: "40px",
          ...DEFAULT_BOX_SHADOW,
          boxSizing: "border-box",
          padding: "1.5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "0.25rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
          }}
        >
          {t("hoursSaved")}
        </Typography>
        <Typography
          sx={{
            fontSize: "50px",
            lineHeight: "50px",
            fontWeight: "500",
          }}
        >
          {getHoursSaved()}
        </Typography>
        <GradientDivider
          sx={{
            "&&": { marginBottom: "0.5rem" },
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "1.75rem",
            borderRadius: "40px",
            background:
              "linear-gradient(90deg, rgba(178,221,255,1) 0%, rgba(232,241,250,1) 30%, rgba(232,241,250,1) 31%)",
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: theme.palette.primary[700],
            }}
          >
            {t("timeSpent")}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: theme.palette.grey[300],
            }}
          >
            {t("timeSpentDescription")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "35%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "75%",
            backgroundColor: theme.palette.grey[100],
            borderRadius: "40px 40px 40px 0",
            ...DEFAULT_BOX_SHADOW,
            boxSizing: "border-box",
            padding: "1.5rem 1.5rem 0 1.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "0.25rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
            }}
          >
            {t("summariesDone")}
          </Typography>
          <Typography
            sx={{
              fontSize: "50px",
              lineHeight: "50px",
              fontWeight: "500",
            }}
          >
            {doctorProfile.summariesDone
              ? parseInt(doctorProfile?.summariesDone)
              : 0}
          </Typography>
          <GradientDivider
            sx={{
              "&&": {
                marginBottom: "0.5rem",
              },
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "2.5rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              {t("amountOfFilesCreated")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "29.17%",
          }}
        >
          <CardBottom right="135px" />
          <TransparentButton
            sx={{
              marginLeft: "auto",
              marginTop: "auto",
              padding: "0",
              whiteSpace: "nowrap",
            }}
            onClick={() => navigate("/open")}
          >
            {t("openCases")}
          </TransparentButton>
        </Box>
      </Box>
    </Box>
  );
}
