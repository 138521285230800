import { Box, Typography } from "@mui/material";
import { t } from "i18next";

export default function AdminConfirmation() {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "600",
        }}
      >
        {t("waitAdmin")}
      </Typography>
    </Box>
  );
}
