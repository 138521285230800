import { Box, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import {
  SignInFormStyles,
  StyledSignInButton,
  StyledSignInInput,
} from "../../styles/SignIn.styles";

type PasswordCodeValues = {
  code: string;
};

export default function PasswordCode() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email] = useState<string>(useLocation().state);
  const forgottenPasswordValidationSchema = object().shape({
    code: string().required(t("required")),
  });

  const passwordCode = async (values: PasswordCodeValues) => {
    try {
      if (values.code) {
        navigate("/new", {
          state: {
            email,
            code: values.code,
          },
        });
      }
    } catch (error) {}
  };

  const initialFormValues = { code: "" };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: forgottenPasswordValidationSchema,
    onSubmit: passwordCode,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={SignInFormStyles}>
      <Typography
        sx={{
          fontSize: "125%",
          height: "1.8rem",
          marginTop: "1rem",
        }}
      >
        {t("confirmationCodeHeader")}
      </Typography>
      <Box>
        <Typography
          sx={{
            color: theme.palette.grey[300],
            marginBottom: "0.25rem",
          }}
        >
          {t("confirmationCode")}
        </Typography>
        <StyledSignInInput
          id="code"
          placeholder={t("enterConfirmationCode")}
          name="code"
          variant="outlined"
          value={formik.values.code}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
        />

        <StyledSignInButton
          type="submit"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          sx={{
            marginTop: "3.65rem",
          }}
        >
          {t("next")}
        </StyledSignInButton>
      </Box>
    </Box>
  );
}
