import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { DEFAULT_BOX_SHADOW, formatBytes } from "../../../utils";
import LinearWithValueLabel from "./LinearWithValueLabel";

type UploadBoxProps = {
  upload: any;
  isAudio?: boolean;
};

export default function UploadBox({ upload, isAudio }: UploadBoxProps) {
  const theme = useTheme();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isUploading, setIsUploading] = useState(false);

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    }
  };

  const uploadFile = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setIsUploading(true);
      setUploadedFile(event.target.files[0]);
      await upload(event.target.files[0]);
      setIsUploading(false);
    }
  };

  const handleDrop = async (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setIsUploading(true);
      setUploadedFile(e.dataTransfer.files[0]);
      await upload(e.dataTransfer.files[0]);
      setDragActive(false);
      setIsUploading(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: "60%",
        height: "20rem",
        padding: "2rem",
        borderRadius: "40px",
        ...DEFAULT_BOX_SHADOW,
        outline: dragActive
          ? `2px solid ${theme.palette.primary.main}`
          : "none",
      }}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      component="label"
    >
      {!uploadedFile ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            gap: "1rem",
          }}
        >
          <Box
            component="img"
            src="/svg/upload.svg"
            sx={{
              width: "3.5rem",
            }}
          />
          <Typography>
            <Box
              component="span"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {t("clickToUpload")}
            </Box>{" "}
            {t("orDragAndDrop")}
          </Typography>
          <Box
            component="input"
            hidden
            accept={isAudio ? "audio/*" : "text/plain"}
            type="file"
            onChange={uploadFile}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {isUploading ? (
            <Box
              component="img"
              src="/svg/trash.svg"
              sx={{
                width: "1.5rem",
                position: "absolute",
                top: "0",
                right: "0",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                setUploadedFile(undefined);
              }}
            />
          ) : (
            <Box
              component="img"
              src="/svg/check.svg"
              sx={{
                width: "1.5rem",
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={(e) => {
                e.preventDefault();
                setUploadedFile(undefined);
              }}
            />
          )}

          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="img"
              src="/svg/uploadFile.svg"
              sx={{
                height: "2.5rem",
              }}
            />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {uploadedFile.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {formatBytes(uploadedFile.size)}
              </Typography>
              <LinearWithValueLabel
                size={uploadedFile.size}
                isUploading={isUploading}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
