import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { StyledCenteredMobileText } from "../../styles/Landing.styles";
import { DEFAULT_BOX_SHADOW } from "../../utils";
import CardTopping from "../consultation/utils/CardTopping";
import AdminConfirmation from "./AdminConfirmation";
import ChangeTemporaryPassword from "./ChangeTemporaryPassword";
import ConfirmSignUp from "./ConfirmSignUp";
import ForgottenPassword from "./ForgottenPassword";
import PasswordCode from "./PasswordCode";
import SetNewPassword from "./SetNewPassword";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import WaitingListSignUp from "./WaitingListSignUp";

export default function AuthRouting() {
  const location = useLocation();
  const [isSignUp, setIsSignUp] = useState(location.pathname === "/signup");
  const navigate = useNavigate();
  const theme = useTheme();

  const pathMappgins = {
    "/": t("signIn"),
    "/signup": t("signUp"),
    "/confirm-signup": t("signUp"),
    "/admin-confirmation": t("signUp"),
    "/mobile": t("signIn"),
    "/forgotten": t("remind"),
    "/temporary": t("signIn"),
    "/code": t("code"),
    "/new": t("new"),
    "/waiting": t("signUp"),
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Box
        sx={(theme) => ({
          position: "relative",
          maxWidth: "600px",
          [theme.breakpoints.down("lg")]: {
            alignSelf: "center",
            width: "100%",
          },
        })}
      >
        <CardTopping>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            {pathMappgins[location.pathname]}
          </Typography>
        </CardTopping>
        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "0 40px 40px 0",
            ...DEFAULT_BOX_SHADOW,
            boxSizing: "border-box",
            padding: "2rem",
            maxWidth: "600px",
          }}
        >
          <Routes>
            <Route path="*" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="confirm-signup" element={<ConfirmSignUp />} />
            <Route path="admin-confirmation" element={<AdminConfirmation />} />
            <Route path="temporary" element={<ChangeTemporaryPassword />} />
            <Route path="waiting" element={<WaitingListSignUp />} />
            <Route path="forgotten" element={<ForgottenPassword />} />
            <Route path="code" element={<PasswordCode />} />
            <Route path="new" element={<SetNewPassword />} />
          </Routes>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={(theme) => ({
              width: "75%",
              height: "3.5rem",
              backgroundColor: theme.palette.secondary.main,
              borderRadius: "0 0 40px 40px",
              position: "relative",
              maxWidth: "460px",
              ...DEFAULT_BOX_SHADOW,
              [theme.breakpoints.down("md")]: {
                maxWidth: "70%",
              },
            })}
          ></Box>
          <Box
            sx={{
              width: "30px",
              height: "30px",
              zIndex: "99999",
              backgroundImage: `radial-gradient(circle at 100% 0, transparent 0%, transparent 30px, ${theme.palette.secondary.main} 30px);`,
              transform: "rotate(90deg)",
            }}
          />
        </Box>
      </Box>
      <StyledCenteredMobileText>
        {!isSignUp ? (
          <Box component="span">
            {t("noAccount")}{" "}
            <Box
              component="span"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/signup");
                setIsSignUp(true);
              }}
            >
              {t("applicationAccess")}
            </Box>
          </Box>
        ) : (
          <Box
            component="span"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
              setIsSignUp(false);
            }}
          >
            {t("goBackToSignIn")}
          </Box>
        )}
      </StyledCenteredMobileText>
    </Box>
  );
}
