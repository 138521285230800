import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../../../Api";
import { Consultation, ConsultationInfo } from "../../../customTypes";
import { TransparentButton } from "../../../styles/Buttons.styles";
import {
  ANAMNESIS,
  DEFAULT_BOX_SHADOW,
  DEFAULT_TRANSITION,
  convertToJSON,
  convertToMMSS,
  convertToTableDate,
} from "../../../utils";
import Loading from "../../Loading";
import { useConsultation } from "../../consultation/custom/useConsultation";
import CardTopping from "../../consultation/utils/CardTopping";
import Search from "../Search";
import EnhancedTableHead from "./EnhancedTableHead";
import Status from "./cell/Status";
import TimeLeft from "./cell/TimeLeft";

const maxStringSort = "zzzzzzzzzzzzzzzzzz";

function descendingComparator(
  a: Consultation,
  b: Consultation,
  orderBy: keyof ConsultationInfo
) {
  let aComparator = a[orderBy];
  let bComparator = b[orderBy];
  if (!aComparator) {
    aComparator = maxStringSort;
  }
  if (!bComparator) {
    bComparator = maxStringSort;
  }
  if (aComparator && bComparator) {
    if (aComparator === bComparator) {
      return b["createdTimestamp"] < a["createdTimestamp"]
        ? -1
        : b["createdTimestamp"] > a["createdTimestamp"]
        ? 1
        : 0;
    } else {
      return bComparator < aComparator ? -1 : bComparator > aComparator ? 1 : 0;
    }
  } else {
    return 0;
  }
}

export type Order = "asc" | "desc";

function getComparator(
  order: Order,
  orderBy: keyof ConsultationInfo
): (a: any, b: any) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type EhnancedTableProps = {
  selectedSortBy: keyof ConsultationInfo;
  setSelectedSortBy: Dispatch<SetStateAction<keyof ConsultationInfo>>;
  presetTypeFilter?: string;
  rowsPerPageNo?: number;
  title?: string;
  showAll?: boolean;
};

export default function EnhancedTable({
  selectedSortBy,
  setSelectedSortBy,
  presetTypeFilter,
  rowsPerPageNo,
  title,
  showAll,
}: EhnancedTableProps) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] =
    useState<keyof ConsultationInfo>("createdTimestamp");
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = rowsPerPageNo || 7;
  const navigate = useNavigate();
  const [consultations, setConsultations] = useState<ConsultationInfo[]>([]);
  const [filteredConsultations, setFilteredConsultations] = useState<
    ConsultationInfo[]
  >([]);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const { downloadConsultation } = useConsultation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getConsultations(), []);

  const getConsultations = () => {
    sendRequest("api/consultations", "GET").then(async (result) => {
      if (!result) return;
      let cons: ConsultationInfo[] = convertToJSON(result);
      if (presetTypeFilter) {
        cons = cons.filter((c) =>
          presetTypeFilter.includes(c.presetType || ANAMNESIS)
        );
      }
      setConsultations(cons);
      setFilteredConsultations(cons);
      setIsLoading(false);
    });
  };

  const updateConsultationName = (
    createdTimestamp: string,
    consultationName?: string
  ) => {
    if (consultationName) {
      sendRequest(
        "api/consultation",
        "PUT",
        JSON.stringify({
          createdTimestamp: createdTimestamp,
          consultationName: consultationName,
        })
      ).then(() => getConsultations());
    }
  };

  const markAlreadyCopied = (createdTimestamp: string) => {
    sendRequest(
      "api/consultation",
      "PUT",
      JSON.stringify({
        createdTimestamp: createdTimestamp,
        alreadyCopiedSummary: true,
      })
    ).then(() => getConsultations());
  };

  const handleRequestSort = (
    property: keyof ConsultationInfo,
    direction?: string
  ) => {
    let isAsc = orderBy === property && order === "asc";

    if (direction) {
      isAsc = direction === "desc";
    }

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSelectedSortBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows?.map((n) => n.createdTimestamp);
      if (newSelected) {
        setSelected(newSelected);
      }
      return;
    } else {
      setSelected([]);
    }
  };

  const handleSelectClick = (
    event: React.MouseEvent<unknown>,
    createdTimestamp: string
  ) => {
    const selectedIndex = selected.indexOf(createdTimestamp);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, createdTimestamp);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (createdTimestamp: string) =>
    selected.indexOf(createdTimestamp) !== -1;

  const visibleRows = useMemo(() => {
    if (filteredConsultations) {
      let visibleConsultations = filteredConsultations.sort(
        getComparator(order, orderBy)
      );
      if (!showAll) {
        visibleConsultations = filteredConsultations.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      }
      return visibleConsultations;
    }
  }, [order, orderBy, page, filteredConsultations, showAll]);

  const pages = useMemo(
    () =>
      filteredConsultations
        ? Math.ceil(filteredConsultations.length / rowsPerPage)
        : 0,
    [filteredConsultations]
  );

  const deleteConsultations = async (consultationToDelete?: string[]) => {
    const s = consultationToDelete || selected;
    const confirmDelete = window.confirm(
      `${t("areYouSure")} ${
        s.length > 1
          ? t("theseConsultations").replace("{}", s.length.toString())
          : t("thisConsultation")
      }?`
    );
    if (confirmDelete) {
      sendRequest(
        `api/consultations?createdTimestamps=${s.join(",")}`,
        "DELETE"
      ).finally(() => {
        getConsultations();
        setSelected([]);
      });
    }
    setPage(0);
  };

  const downloadConsultations = async () => {
    const consultationsToDownload = filteredConsultations.filter((c) =>
      selected.includes(c.createdTimestamp)
    );
    consultationsToDownload.forEach(async (ctd) => {
      await sendRequest(
        `api/consultation?createdTimestamp=${ctd.createdTimestamp}`,
        "GET"
      ).then((result) => {
        if (!result) return;
        const c: Consultation = convertToJSON(result);
        downloadConsultation(c);
      });
    });
  };

  const trashStyles =
    selected.length > 0
      ? {
          cursor: "pointer",
          filter:
            "brightness(0) saturate(100%) invert(69%) sepia(25%) saturate(728%) hue-rotate(315deg) brightness(101%) contrast(98%)",
          "&:hover": {
            filter:
              "brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(303%) hue-rotate(187deg) brightness(104%) contrast(93%)",
          },
        }
      : {
          filter:
            "brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(303%) hue-rotate(187deg) brightness(104%) contrast(93%)",
        };

  const downloadStyles =
    selected.length > 0
      ? {
          cursor: "pointer",
          filter:
            "brightness(0) saturate(100%) invert(15%) sepia(18%) saturate(2312%) hue-rotate(195deg) brightness(93%) contrast(88%)",
          "&:hover": {
            filter:
              "brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(303%) hue-rotate(187deg) brightness(104%) contrast(93%)",
          },
        }
      : {
          filter:
            "brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(303%) hue-rotate(187deg) brightness(104%) contrast(93%)",
        };

  return isLoading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "3.5rem",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <CardTopping>
            {selected.length > 0 ? (
              <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                {selected.length} {t("selected")}
              </Typography>
            ) : (
              <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                {title ? title : t("allSessions")}
              </Typography>
            )}
          </CardTopping>
          <Box
            onClick={selected.length > 0 ? downloadConsultations : () => {}}
            component="img"
            src="/svg/download.svg"
            sx={{
              ...DEFAULT_TRANSITION,
              padding: "0.5rem",
              borderRadius: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...downloadStyles,
            }}
          />
          <Box
            onClick={() => {
              if (selected.length > 0) {
                deleteConsultations();
              }
            }}
            component="img"
            aria-label="deleteButton"
            src="/svg/trash.svg"
            sx={{
              ...DEFAULT_TRANSITION,
              padding: "0.5rem",
              borderRadius: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...trashStyles,
            }}
          />
        </Box>
        <Search
          setFilteredConsultations={setFilteredConsultations}
          consultations={consultations}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          width: "100%",
          padding: "1rem 0 2rem 0",
          borderRadius: "0 40px 40px 40px",
          ...DEFAULT_BOX_SHADOW,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <TableContainer
          sx={{
            borderBottom:
              visibleRows && visibleRows.length > 0
                ? `1px solid ${theme.palette.text.secondary}`
                : "none",
          }}
        >
          <Table sx={{ minWidth: 750 }} size={"medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={visibleRows?.length || 0}
              selectedSortBy={selectedSortBy}
            />
            <TableBody>
              {visibleRows?.map((row, index) => {
                const isItemSelected = isSelected(row.createdTimestamp);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    key={row.createdTimestamp}
                    selected={isItemSelected}
                    sx={{
                      cursor: "pointer",
                      height: "4.5rem",
                      ".MuiTableCell-root": {
                        borderBottomColor:
                          index < visibleRows.length - 1
                            ? theme.palette.primary.main
                            : "none",
                      },
                      backgroundColor: isItemSelected
                        ? `${theme.palette.grey[50]} !important`
                        : "transparent",
                      ...DEFAULT_TRANSITION,
                      "&:hover": {
                        backgroundColor: theme.palette.grey[50],
                      },
                    }}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        paddingLeft: "2rem",
                      }}
                    >
                      <Checkbox
                        icon={
                          <Box
                            component="img"
                            src="/svg/checkboxUnchecked.svg"
                          />
                        }
                        checkedIcon={
                          <Box component="img" src="/svg/checkboxChecked.svg" />
                        }
                        checked={isItemSelected}
                        sx={{
                          zIndex: "9999",
                        }}
                        onClick={(event) =>
                          handleSelectClick(event, row.createdTimestamp)
                        }
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <TextField
                        id="consultation-name-input"
                        defaultValue={row.consultationName}
                        sx={{
                          fontSize: "50%",
                          width: "90%",
                          zIndex: "9999",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: theme.palette.grey[300],
                            },
                            "&:hover": {
                              outlineColor: theme.palette.text.primary,
                            },
                            "&.Mui-focused fieldset": {
                              outlineColor: theme.palette.text.primary,
                              borderWidth: "2px",
                            },
                          },
                        }}
                        InputProps={{
                          sx: {
                            fontSize: "200%",
                            padding: "0",
                            height: "2.5rem",
                            backgroundColor: "white",
                            borderRadius: "40px",
                            input: {
                              "&::placeholder": {
                                color: theme.palette.grey[300],
                                opacity: "1",
                              },
                            },
                          },
                        }}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                          updateConsultationName(
                            row.createdTimestamp,
                            e.currentTarget.value
                          )
                        }
                        variant="outlined"
                        placeholder="Add"
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (e.key === "Enter") {
                            updateConsultationName(
                              row.createdTimestamp,
                              (e.target as HTMLInputElement).value
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className="dateCell"
                      onClick={() => {
                        navigate(`/consultation/${row.createdTimestamp}`);
                      }}
                    >
                      <Typography>
                        {convertToTableDate(row.createdTimestamp)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/consultation/${row.createdTimestamp}`);
                      }}
                      sx={{
                        width: "20rem",
                      }}
                    >
                      <Typography>
                        {convertToMMSS(row.recordingDuration)} min
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      onClick={() => {
                        navigate(`/consultation/${row.createdTimestamp}`);
                      }}
                    >
                      <Status
                        consultationStatus={row.consultationStatus}
                        consultationType={row.presetType}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        navigate(`/consultation/${row.createdTimestamp}`);
                      }}
                    >
                      <TimeLeft ttl={row.ttl} />
                    </TableCell>
                    <TableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          gap: "1rem",
                          width: "100%",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {row.alreadyCopiedSummary ? (
                          <Box
                            component="img"
                            src="/svg/checkGreen.svg"
                            sx={{
                              height: "1.25rem",
                            }}
                          />
                        ) : (
                          <Box
                            onClick={() => {
                              navigator.clipboard.writeText(
                                row?.summaries?.at(-1) || ""
                              );
                              markAlreadyCopied(row.createdTimestamp);
                            }}
                            component="img"
                            aria-label="copyButton"
                            src="/svg/copy.svg"
                            sx={{
                              ...DEFAULT_TRANSITION,
                              cursor: "pointer",
                              filter:
                                "brightness(0) saturate(100%) invert(15%) sepia(18%) saturate(2312%) hue-rotate(195deg) brightness(93%) contrast(88%)",
                              "&:hover": {
                                filter:
                                  "brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(303%) hue-rotate(187deg) brightness(104%) contrast(93%)",
                              },
                            }}
                          />
                        )}

                        <Box
                          onClick={() => {
                            deleteConsultations([row.createdTimestamp]);
                          }}
                          component="img"
                          aria-label="deleteButton"
                          src="/svg/trash.svg"
                          sx={{
                            ...DEFAULT_TRANSITION,
                            cursor: "pointer",
                            filter:
                              "brightness(0) saturate(100%) invert(69%) sepia(25%) saturate(728%) hue-rotate(315deg) brightness(101%) contrast(98%)",
                            "&:hover": {
                              filter:
                                "brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(303%) hue-rotate(187deg) brightness(104%) contrast(93%)",
                            },
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {!showAll && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 2rem",
              marginTop: "auto",
            }}
          >
            <Box
              sx={{
                width: "7rem",
              }}
            >
              <TransparentButton
                onClick={() => {
                  setPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
                }}
                disabled={page <= 0}
              >
                {t("previous")}
              </TransparentButton>
            </Box>

            <Typography
              sx={{
                width: "7rem",
              }}
            >
              {t("page")} {page + 1} {t("of")} {pages > 0 ? pages : 1}
            </Typography>
            <Box
              sx={{
                width: "7rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TransparentButton
                onClick={() => {
                  setPage((prevPage) =>
                    prevPage < pages - 1 ? prevPage + 1 : pages - 1
                  );
                }}
                disabled={page >= pages - 1}
              >
                {t("next")}
              </TransparentButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
