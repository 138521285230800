import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import FullLengthDivider from "../custom/FullLengthDivider";

export default function SettingsItem({
  children,
  label,
  labelComponent,
}: {
  children: React.ReactNode;
  label?: string;
  labelComponent?: JSX.Element;
}) {
  const theme = useTheme();
  return (
    <>
      <FullLengthDivider color={theme.palette.grey[300]} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "3.5rem",
          padding: "1rem",
        }}
      >
        {label && (
          <Typography
            sx={{
              width: "25rem",
              fontWeight: "500",
            }}
          >
            {t(label)}
          </Typography>
        )}
        {labelComponent && labelComponent}
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}
