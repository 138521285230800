import { Box, CircularProgress, Typography } from "@mui/material";
import { Auth, Hub } from "aws-amplify";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import {
  SignInFormStyles,
  StyledSignInButton,
} from "../../styles/SignIn.styles";
import { UserContext } from "../context/UserContext";
import { useDoctorProfile } from "../doctor/custom/useDoctorProfile";
import AuthField from "./AuthField";

type ConfirmSignUpValues = {
  confirmationCode: string;
};

const confirmSignUpValidationSchema = object().shape({
  confirmationCode: string().required("Required"),
});

export default function ConfirmSignUp() {
  const [signingUp, setSigningUp] = useState<boolean>(false);

  const { setUser, setDoctorProfile } = useContext(UserContext);
  const navigate = useNavigate();
  const [amplifyError, setAmplifyError] = useState<string>();

  const [{ username, name, medicalDomain }] = useState<any>(
    JSON.parse(localStorage.getItem("currentUserSignUp") || "")
  );

  const { createDoctorProfile } = useDoctorProfile();

  function listenToAutoSignInEvent() {
    Hub.listen("auth", async ({ payload }) => {
      setSigningUp(true);
      const { event } = payload;
      if (event === "autoSignIn") {
        try {
          createDoctorProfile(username, name, medicalDomain);
        } catch (error) {
          setUser(undefined);
          setDoctorProfile(undefined);
          localStorage.removeItem("currentUserSignUp");
          await Auth.signOut();
          navigate("/");
        }
      } else if (event === "autoSignIn_failure") {
        navigate("/");
      }
      setSigningUp(false);
    });
  }

  const confirm = async (values: ConfirmSignUpValues) => {
    setAmplifyError("");
    try {
      const confirmationCode = values.confirmationCode;
      if (confirmationCode) {
        listenToAutoSignInEvent();
        await Auth.confirmSignUp(username, confirmationCode);
      }
    } catch (error) {
      setAmplifyError((error as any).message);
      console.log("error signing in", error);
    }
    setSigningUp(false);
  };

  const initialFormValues = { confirmationCode: "" };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: confirmSignUpValidationSchema,
    onSubmit: confirm,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={SignInFormStyles}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <AuthField
          formik={formik}
          formikValue="confirmationCode"
          placeholder="enterConfirmationCode"
        />
      </Box>
      {amplifyError && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.error.main,
            fontWeight: "600",
            fontSize: "1.25rem",
            position: "absolute",
            zIndex: "9",
            right: "30%",
            bottom: "0.7rem",
            [theme.breakpoints.down("md")]: {
              left: "2rem",
              fontSize: "1rem",
            },
            [theme.breakpoints.down("sm")]: {
              maxWidth: "7rem",
            },
          })}
        >
          {amplifyError}
        </Typography>
      )}

      <StyledSignInButton
        type="submit"
        onClick={() => {
          if (
            Object.keys(formik.errors).length === 0 &&
            formik.values.confirmationCode
          ) {
            setSigningUp(true);
          }
        }}
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {signingUp ? (
          <CircularProgress size={"1.25rem"} style={{ color: "white" }} />
        ) : (
          t("signUp")
        )}
      </StyledSignInButton>
    </Box>
  );
}
