import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { DarkGreyButton } from "../../../styles/Buttons.styles";
import { DEFAULT_BOX_SHADOW } from "../../../utils";
import { ConsultationContext } from "../../context/ConsultationContext";
import { UserContext } from "../../context/UserContext";
import PrettyDropdown from "../../custom/PrettyDropdown";
import AudioRecorder from "./AudioRecorder";

export default function RecordAudioContent() {
    const { update, consultation, getConsultation, createSummary } =
        useContext(ConsultationContext);
    const { user } = useContext(UserContext);
    const [mobileUrl, setMobileUrl] = useState<string | undefined>();

    useEffect(() => {
        if (user) {
            const url = `${window.location.href.replace(
                "/consultation",
                ""
            )}/mobile?createdTimestamp=${
                consultation?.createdTimestamp
            }&lang=${localStorage.getItem("language")}`;
            setMobileUrl(url);
        }
    }, []);

    const onRecordingFinishedClick = async () => {
        const newConsultation = await getConsultation();
        if (newConsultation && newConsultation.audio) {
            await createSummary();
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
                width: "100%",
                height: "25rem",
            }}
        >
            <Box
                sx={{
                    backgroundColor: "white",
                    width: "60%",
                    height: "24.5rem",
                    padding: "2rem",
                    borderRadius: "40px",
                    ...DEFAULT_BOX_SHADOW,
                }}
            >
                {consultation && (
                    <AudioRecorder
                        update={update}
                        recordingDuration={consultation.recordingDuration}
                    />
                )}
            </Box>
            <Box
                sx={{
                    width: "40%",
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                }}
            >
                <PrettyDropdown
                    currentlySelected={t("recordViaMobilePhone")}
                    customContent={
                        <Box
                            sx={{
                                height: "20.75rem",
                                padding: "1rem 1rem 24px 1rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "1rem",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "1rem",
                                        height: "100%",
                                        justifyContent: "space-between",
                                        alignItems: "start",
                                        flexBasis: "100%",
                                        flex: "1",
                                    }}
                                >
                                    {mobileUrl && (
                                        <Box>
                                            <QRCode
                                                value={mobileUrl}
                                                style={{
                                                    width:
                                                        window.innerWidth < 1200
                                                            ? "8rem"
                                                            : "12rem",
                                                    height:
                                                        window.innerWidth < 1200
                                                            ? "8rem"
                                                            : "12rem",
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "1rem",
                                        flexBasis: "100%",
                                        flex: "1",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        {t("mobilePhoneRecord1")}
                                    </Typography>
                                </Box>
                            </Box>
                            <DarkGreyButton
                                sx={{
                                    fontWeight: "500",
                                    "&&": {
                                        width: "100%",
                                    },
                                }}
                                onClick={onRecordingFinishedClick}
                            >
                                {t("markAsFinished")}
                            </DarkGreyButton>
                        </Box>
                    }
                />
            </Box>
        </Box>
    );
}
